.Footer {
  margin-top: 64px;
  width: 100%;
  padding: 32px 0px;
  background-color: var(--color-primary-11);
  display: flex;
  justify-content: center;

  .Logo {
    width: 167px;
    height: 32px;
    margin-bottom: 48px; }

  .ActiveButton {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 32px;
    .ActiveIcon {
      width: 28px;
      height: 28px;
      font-size: 14px;
      line-height: 28px;
      top: 3px;
      right: 3px; } } }

.CtBlock {
  max-width: 300px;
  flex-shrink: 0;
  color: #C3DFE6;
  font-weight: 500;
  font-size: 12px;
 }  // margin-bottom: 12px
.Footer-Container {
  display: flex;
  justify-content: space-between; }

.FooterBlock {
  display: flex;
  .SocIcon {
    font-size: 16px; } }

.FooterIcon {
  width: 32px;
  height: 32px;
  text-align: center;
  font-size: 32px;
  line-height: 32px;
  color: var(--color-primary-6);
  margin-right: 12px; }
.LinkSlider {
  color: #C3DFE6; }
.FooterLinks {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  color: #C3DFE6;
  font-weight: 500;
  font-size: 12px;
  align-content: start;
  .Title {
    display: block;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase; }
  a {
    color: #C3DFE6;
    &:hover {
      color: var(--color-primary-6);
      text-decoration: underline !important; } } }

@media screen and ( max-width: 576px ) {
  .Footer {
    padding: 24px 0px !important;
    margin-top: 48px;
    .Logo {
      margin-bottom: 24px;
      justify-self: center; } }
  .Footer-Container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px; } }
