.PointTile {
  padding: 16px;
  cursor: pointer;
  &:not(:last-child) {
    border-bottom: 1px var(--color-tertiary-1 ) solid; }
  .PointTile-Info {
    display: grid;
    grid-template-columns: 80px 1fr;
    grid-gap: 16px;
    align-items: start;
    margin-bottom: 16px;
    .Head {
      display: grid;
      grid-template-columns: 1fr 20px;
      grid-gap: 16px; }

    .Content {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 8px; } }
  .Avatar {
    width: 80px;
    height: 80px;
    .Avatar-Icon {
      width: 100%;
      height: 100%;
      background-color: var(--color-tertiary-1);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      font-size: 32px;
      color: var(--color-tertiary-4); } }
  .Tags {
    display: flex;
    margin-bottom: 16px;
    flex-wrap: wrap;
    .Tag {
      padding: 2px 8px;
      border-radius: 4px;
      color: #fff;
      &:not(:last-child) {
        margin-right: 8px; } } }
  .Totals {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .ShowOnMap {
    margin-top: 16px;
    display: none;
    .Button {
      width: 100%; } } }

@media screen and ( max-width: 1366px ) {
  .PointTile {
    padding: 16px 0px;
    .ShowOnMap {
      display: block; } } }
