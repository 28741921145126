.CancelOrderPopUp {
  min-width: 600px;
  .Heder-Title {
    .Icon {
      color: var(--color-functional-error-regular); } }
  .Text {
    color: var(--color-tertiary-8); }
  .Buttons {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    .Button_theme_primary {
      background-color: var(--color-functional-error-regular); }
    .Button_theme_internal-link {
      color: var(--color-functional-error-regular); } } }
.CancelOrderPopUp-Container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px; }
