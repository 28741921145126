.PhoneConfirm-Form {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  .Text {
    color: var(--color-tertiary-8); } }

.PhoneCodeConfirmation {
  max-width: 600px;

  .CodeInputBlock {
    display: flex;
    align-items: center;
    margin-top: 32px; }
  .ConfirmCodeInput {
    width: 184px !important;
    input {
      width: 40px !important;
      height: 48px !important;
      border: 1px var(--color-tertiary-2) solid !important;
      border-radius: 4px !important;
      color: var(--color-tertiary-10);
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      font-family: var(--font-default);
      &:not(:last-child) {
        margin-right: 8px; } } }
  .ConfirmCodeError {
    margin-top: 8px;
    color: var(--color-functional-error-small); }
  .ConfirmCodeInput_status_fail {
    input {
      border: 1px var(--color-functional-error-regular) solid !important; } }
  .SuccessIcon {
    width: 32px;
    height: 32px;
    margin-left: 16px; }
  .NewCode-Cotnainer {
    margin-top: 32px;
    .NotActiveLink {
      color: var(--color-tertiary-6); } } }
