.CartPopUp {
  min-width: 600px;
  .CartOnPage-Header {
    display: none; }
  .CartOnPage {
    padding: 0;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center; }
  .CartOnPage-Body {
    width: 100%; }
  .CartItem {
    padding: 8px !important; }
  .Buttons {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 32px; }
  .PriceBlock {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border-top: 1px var(--color-tertiary-1) solid;
    padding-top: 16px;
    grid-gap: 16px; }
  .TotalsCaption {
    grid-column: 1/-1; }
  .Price {
    text-align: right; }
  .EmptyCart {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    text-align: center;
    img {
      width: 100%; }
    .Title {
      margin-top: 24px; }
    .Description {
      margin-top: 8px;
      margin-bottom: 40px; } } }

@media screen and ( max-width: 1366px ) {
  .CartPopUp {
    .Buttons {
      width: 100%;
      display: block;
      .Button {
        width: 100%; } }
    .EmptyCart {
      img {
        width: 100%; }
      a, .Button {
        width: 100%; } } } }

@media screen and ( max-width: 576px ) {
  // .CartPopUp
  //   min-width: auto
  //   width: 100%
  //   height: 100vh
  //   box-shadow: var(--shadow-l)
 }  //   top: 0
