.Nav {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  box-shadow: var(--shadow-l);
  background-color: #fff;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
  transition: all 0.4s ease; }
.Nav_theme_shadow {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.07); }
.Nav-Container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
 }    // color: var(--color-tertiary-6)
  .LeftSide {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 48px; }
  .RightSide {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 32px; } }
.Nav-Menu {
  display: grid;
  grid-template-columns: repeat(7, max-content);
  grid-gap: 12px;
  align-items: center;

  .Site-NavLink {
    cursor: pointer !important;
    color: var(--color-tertiary-6);
    position: relative;
    &:hover {
      background-color: #f1f7f9;
      color: var(--color-primary-10); } } }
.Site-NavLink {
  padding: 4px 8px; }
.NavLink_theme_active {
  color: var(--color-primary-10); }

@media screen and ( max-width: 576px ) {
  .Nav-Container {
    align-items: center;
    position: relative;
    .LeftSide {
      grid-template-columns: 1fr;
      .PseudoBlock {
        display: none; } }
    .RightSide {
      grid-gap: 16px; }
    //   grid-template-columns: 1fr
    // .ProfileButton
 }    //   display: none
  .Nav-Menu {
    display: none;
    position: absolute;
    top: 50px;
    grid-template-columns: 1fr;
    width: 100%;
    background-color: #fff;
    left: 0;
    padding: 16px; }
  .PseudoBlock {
    width: 24px; } }
