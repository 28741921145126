/* MainButtons block */

.MainButtons {
  margin-top: -32px;
  display: flex;
  justify-content: center; }
.MainButtons-Container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 32px;
  justify-content: center; }

.MainButton {
  width: 100%;
  padding: 32px;
  border-radius: 16px;
  background-color: var(--color-primary-10);
  box-shadow: 0px 16px 32px rgba(9, 162, 202, 0.1);
  background-size: 270px auto;
  background-repeat: no-repeat;
  background-position: right -16px bottom 0px;
  h2 {
    color: #fff;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 24px;
    text-transform: uppercase; }
  .ActiveButton {
    margin-top: 14px; } }

.MainButton_theme_light {
  background-size: 188px auto;
  background-position: right 16px bottom 0px;
  background-color: #f1f7f9;
  h2, p {
    color: var(--color-primary-10) !important; } }

.TextContainer {
  width: calc(100% - 170px); }

.MainButton-BenefitsList {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  margin-bottom: 24px;
  .MainButton-Benefit {
    color: #fff;
    .Icon {
      color: var(--color-primary-6); } } }

@media screen and ( max-width: 576px ) {
  .MainButtons-Container {
    grid-template-columns: 1fr; }
  .MainButton {
    padding: 24px;
    position: relative;
    z-index: 1;
    background-image: none !important; }
  .TextContainer {
    width: 100%; } }
