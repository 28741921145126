.ProfileButton-Container {
  display: flex;
  align-items: center;
  position: relative; }
.ProfileButton-Body {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-tertiary-6);
  text-align: center;
  .Icon {
    font-size: 24px;
    color: var(--color-primary-10);
    margin-bottom: 6px; }
  .Avatar {
    cursor: pointer; } }
@media screen and ( max-width: 576px ) {
  .ProfileButton {
 } }    // width: 64px
