.MobileStepper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  z-index: 1000;
  padding: 40px 16px;
  .Label {
    color: var(--color-tertiary-6);
    font-size: 14px;
    line-height: 20px; }
  .MobileStepper-Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--color-tertiary-6); }
  .Title {
    margin: 24px 0px; }
  .Checkbox-Container {
    margin: 14px 0px; }

  .SportChooser {
    .ListElement {
      padding: 16px;
      border: 1px var(--color-tertiary-2) solid;
      border-radius: 12px; }
    .SportChooser-List {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 8px; } }
  .Buttons {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 16px;
    border-top: 1px var(--color-tertiary-2) solid;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    background-color: #fff;
    grid-gap: 12px; }
  .LocationChooser {
    height: calc(100vh - 81px - 48px - 80px);
    overflow: auto;
    .LocationChooser-List {
      .ListElement {
        border-bottom: 1px var(--color-tertiary-1) solid;
        .Icon-Container {
          background: none; }
        &:last-child {
          border-bottom: 0px; } } } }
  .DateChooser {
    height: calc(100vh - 81px - 48px - 80px - 140px);
    overflow: auto;
    .react-datepicker {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .react-datepicker__month-container {
        justify-self: center; } } } }
