.OrderData {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  .OrderNum {
    margin-top: -32px;
    color: var(--color-tertiary-6); }
  .DetailsButton {
    display: none; }
  .OrderDetails-Body {
    display: block;
    margin-top: 0px; } }
.OrderData-Title {
  display: flex;
  align-items: center;
  justify-content: space-between; }
.OrderData-Buttons {
  display: flex;
  justify-content: flex-end; }

@media screen and ( max-width: 1366px ) {
  .OrderData-Buttons {
    display: grid;
    grid-template-columns: 1fr;
    .Button {
      width: 100%; } } }
