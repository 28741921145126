.PriceTable {
  width: 100%;
  th {
    font-size: 13px !important; }

  td {
    padding: 4px 8px;
    border-bottom: 1px var(--color-tertiary-1) solid; }
  .ProductChat {
    .Button-IconContainer {
      width: auto;
      height: auto;
      transform: rotate(180deg);
      background-color: var(--color-primary-8) !important; } }
  .PriceTable-PriceButton {
    width: 110px;
    // display: flex
    // justify-content: center
    text-align: center; } }
