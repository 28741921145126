.SchedulePopUp {
  max-width: 800px;
  max-height: calc(100vh - 128px);
  z-index: 11;
  .PopUp-Content {
    overflow-x: auto;
    max-height: calc(100vh - 300px); }
  .Buttons {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    .Button {
      &:first-child {
        margin-right: 16px; } } }
  // .Schedule-Container
  //   display: grid
  //   grid-template-columns: repeat(4, 1fr)
  //   grid-gap: 24px
  .DateTile {
    padding: 4px 8px;
    font-size: 14px;
    border: 1px var(--color-tertiary-2) solid;
    border-radius: 8px;
    display: grid;
    grid-template-columns: 24px 1fr;
    grid-gap: 12px;
    &:hover {
      border: 1px var(--color-primary-8) solid;
      box-shadow: var(--shadow-m); }
    .DateIcon-Container {
      width: 24px;
      height: 24px;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px var(--color-tertiary-2) solid;
      border-radius: 100%;
      color: #fff;
      .Icon {
        margin-right: 0; } } }
  .DateTile_status_active {
    background-color: rgba(195,223,230,.2);
    border: 1px var(--color-primary-8) solid;
    box-shadow: var(--shadow-m);
    .DateIcon-Container {
      background-color: #fff;
      border: 1px var(--color-primary-8) solid;
      color: var(--color-primary-8); } } }

.Doctors {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  margin-top: 24px;
  .ServiceTile {
    padding: 16px;
    border-radius: 8px;
    background-color: #fff;
    border: 1px var(--color-tertiary-2) solid;
    width: 100%;
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-gap: 12px;
    align-items: start;
    .NotificationText {
      padding: 16px;
      background-color: #fff3ca;
      border-radius: 8px;
      font-size: 14px;
      margin-bottom: 16px; }
    .Icon {
      font-size: 16px;
      margin-right: 12px; }
    .Title {
      color: var(--color-primary-10);
      grid-column: 1/-1; } }
  .ServiceWithDoctor {
    grid-column: 1/-1; }
  .hzCont {
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-gap: 12px;
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px var(--color-tertiary-2) solid; }
  .DocsContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px; }
  .DoctorTile {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 24px;
    border: 0;
    box-shadow: none;
    align-items: start;
    border: 1px var(--color-tertiary-2) solid;
    &:hover {
      border: 1px var(--color-primary-8) solid;
      box-shadow: var(--shadow-m); }

    .Icon-Container {
      width: 24px;
      height: 24px;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px var(--color-tertiary-2) solid;
      border-radius: 100%;
      color: #fff;
      background-color: #fff;
      .Icon {
        margin-right: 0; } }
    .DoctorAvatar {
      align-self: start;
      background-color: #fff; }
    .DoctorData {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 16px;
      width: 100%; }
    .Doctor-Params {
      display: grid;
      grid-template-columns: repeat(3, max-content) 1fr;
      grid-gap: 24px;
      width: 100%;
      align-items: start;
      .Param {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 4px; } }
    .ChooseDateButton {
      justify-self: end; }
    .CancelDateButton {
      background-color: var(--color-functional-error-regular);
      margin-left: 16px; }
    .Button-IconContainer {
      width: auto;
      height: auto;
      background: none; } }
  .DoctorTile_status_active {
    background-color: rgba(195,223,230,.2);
    border: 1px var(--color-primary-8) solid;
    box-shadow: var(--shadow-m);
    .Icon-Container {
      color: var(--color-primary-8) !important; } } }

@media screen and ( max-width: 576px ) {
  .Doctors {
    .ServiceTile {
      grid-template-columns: 1fr; }
    .DoctorTile {
      grid-template-columns: 1fr;
      .Doctor-Params {
        grid-template-columns: 1fr;
        grid-column: 1/-1; }
      .ChooseDateButton {
        justify-self: start; } }
    .hzCont {
      grid-template-columns: 1fr; } }
  .SchedulePopUp {
    max-height: 100vh;
    .PopUp-Content {
      max-height: calc(100vh - 128px);
      height: 100%; }
    // .Schedule-Container
    //   display: grid
 } }    //   grid-template-columns: repeat(2, 1fr)
