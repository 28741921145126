.CheckoutButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  grid-column: 1/-1;
  width: 100%;
  .CheckoutButtons-Container {
    display: flex;
    justify-content: space-between;
    align-items: center; } }

@media screen and ( max-width: 576px ) {
  .CheckoutButtons {
    position: fixed;
    bottom: 0px;
    left: 0;
    background-color: #fff;
    padding: 24px;
    box-shadow: var(--shadow-m);
    z-index: 10;
    min-height: 168px;
    .CheckoutButtons-Container {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 24px;
      .Button {
        width: 320px;
        justify-self: center; }
      .EmptyStep {
        display: none; }
      .Button_theme_primary {
        order: 0; }
      .Button_theme_secondary {
        order: 1; } } } }
