.MapMarker-Container {
  display: flex;
  position: relative; }
.MapMarker {
  flex-shrink: 0;
  width: 27px;
  height: 42px;
  transform: translateY(-100%);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: start;
  background-color: transparent;
  .Icon {
    margin-top: 8px;
    color: var(--color-primary-8);
    font-size: 12px; } }
.MapMarker_theme_active {
  z-index: 1000;
  .Icon {
    color: #fff; } }
.MapMarker-Text {
  transform: translateY(-100%);
  flex-shrink: 0;
  margin-left: 4px;
  color: var(--color-primary-11);
  text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff; }
