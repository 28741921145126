.OnePageCheck {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  margin-top: 24px;
  .OnePageCheckTile {
    padding: 16px;
    border-radius: 8px;
    background-color: #fff;
    border: 1px var(--color-tertiary-2) solid;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, max-content) 1fr;
    grid-gap: 12px;
    align-items: center;
    .Icon-Container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 100%;
      border: 1px var(--color-tertiary-2) solid;
      font-size: 18px;
      color: var(--color-primary-8); }
    .Title {
      color: var(--color-primary-10); }
    .DoctorAvatar {
      align-self: start; }
    .ServiceData {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 16px; }
    .Service-Params {
      display: grid;
      grid-template-columns: repeat(5, max-content);
      grid-gap: 24px; }
    .PriceValue {
      justify-self: end; } } }
