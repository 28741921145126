.PickupPointsNextStep {
  position: fixed;
  width: calc(100% - 80px);
  padding: 20px 80px 20px 0px;
  border-top: 1px var(--color-tertiary-1) solid;
  border-bottom: 1px var(--color-tertiary-1) solid;
  left: 0;
  bottom: 0;
  margin-left: 80px;
  background-color: #fff;
  padding-left: 256px;
  display: flex;
  align-items: center;
  z-index: 100;
  .Info {
    margin-left: 16px;
    grid-gap: 4px; }
  .NextStep, a {
    margin-left: auto; } }

@media screen and ( max-width: 1366px ) {
  .PickupPointsNextStep {
    display: none; } }
