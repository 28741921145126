.CartButton-Body {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-primary-10);
  height: 64px;
  width: 64px;
  .Icon {
    font-size: 24px;
    color: #fff;
    margin-bottom: 6px; }
  .CartCounter {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    position: absolute;
    background: var(--color-primary-6);
    color: #000;
    right: 0px;
    top: 0px; } }

.CartButton {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  color: #fff; }
