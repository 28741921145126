.Checkout {
  display: flex;
  justify-content: center;
  position: relative;
  .Checkout-Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 48px; }
  .Checkout-Title {
    color: var(--color-primary-10); } }

@media screen and ( max-width: 576px ) {
  .Checkout {
    .Checkout-Header {
      display: grid;
      grid-template-columns: 1fr;
      justify-items: center; }
    .Checkout-Title {
      order: 1;
      margin-top: 24px; }
    .Stepper {
      width: 320px !important; }
    .CheckoutButtons {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 24px; } } }
