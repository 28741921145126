.PickupPoints {
  display: grid;
  grid-template-columns: 240px 1fr;
  grid-gap: 40px;
  align-items: start;
  height: calc(100vh - 62px - 120px);
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-right: 0 !important;
  .Info {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    justify-items: start;
    padding-top: 32px; }
  .PickupPoints-Content {
    display: grid;
    grid-template-columns: 380px 1fr;
    height: 100%;
    overflow-y: scroll;
    .PointsPanel {
      width: 100%;
      padding-top: 32px;
      overflow-y: scroll; } } }
.InfoBlock {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  .TextWithButton {
    display: flex;
    align-items: center;
    .Icon {
      margin-left: 8px;
      color: var(--color-primary-11);
      cursor: pointer; } }
  .Caption-Regular {
    color: var(--color-tertiary-6); } }

@media screen and ( max-width: 1366px ) {
  .PickupPoints {
    grid-template-columns: 1fr;
    padding-right: 16px !important;
    height: 100%;
    .Info {
      margin-left: -16px;
      margin-right: -16px;
      padding: 0px 16px 16px 16px;
      border-bottom: 1px var(--color-tertiary-1) solid; }
    .PickupPoints-Content {
      grid-template-columns: 1fr;
      .PointsPanel {
        padding-top: 0; } }
    .LocationsMap {
      display: none; } } }
