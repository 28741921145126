.PasswordStrengthBar {
  margin: 16px 0px 24px 0px;
  .Bar {
    display: flex;
    flex-direction: column;
    div {
      order: 1; }
    p {
      text-align: left !important;
      order: 0;
      font-weight: 700;
      font-size: 14px !important;
      line-height: 20px !important;
      margin-bottom: 4px !important; } }
  .Bar > div > div {
    height: 4px !important; }
  .Text {
    margin-top: 16px;
    list-style-type: disc;
    padding-left: 16px;
    color: var(--color-tertiary-8);
    li {
      &:not(:last-child) {
        margin-bottom: 4px; } } } }
