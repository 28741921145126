.CheckoutSpecialization {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 24px;
  margin-top: 24px;
  .ModuleSpinner-Container {
    grid-column: 1/-1; }
  .CheckoutSpecialization-Title {
    grid-column: 1/-1;
    font-weight: 700;
    font-size: 18px; }

  .SpecializationTile {
    padding: 16px;
    background-color: #fff;
    border-radius: 8px;
    border: 1px var(--color-tertiary-2) solid;
    display: flex;
    align-items: center;
    color: var(--color-primary-10);
    cursor: pointer;
    .Icon-Container {
      background-color: var(--color-tertiary-04);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 42px;
      height: 42px;
      font-size: 18px;
      color: var(--color-primary-8);
      border-radius: 100%;
      margin-right: 12px;
      flex-shrink: 0;
      img {
        height: 28px; } }
    &:hover {
      border: 1px var(--color-primary-8) solid;
      box-shadow: var(--shadow-m); } }
  .SpecializationTile_theme_active {
    background-color: rgba(195,223,230,.2);
    box-shadow: var(--shadow-m);
    border: 1px var(--color-primary-8) solid; } }

@media screen and ( max-width: 576px ) {
  .CheckoutSpecialization {
    grid-template-columns: 1fr; } }
