.EmptyArea {
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--color-tertiary-04);
  color: var(--color-tertiary-6);
  border-radius: 8px; }
