.Spoiler_status_expanded {
  .Button {
    .Icon {
      transform: rotate(180deg); } }
  .OrderDetails-Body {
    display: block; } }
.OrderDetails-Body {
  margin-top: 24px;
  display: none;
  .Info-Header {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    align-items: start;
    padding-bottom: 16px;
    border-bottom: 1px var(--color-tertiary-1) solid; }
  .InfoBlock {
    grid-gap: 4px; } }
.OrderDetails-Products {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  .CartItemValued {
    border-bottom: 1px var(--color-tertiary-1) solid; }
  .Totals {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    padding-top: 0;
    border-bottom: 1px var(--color-tertiary-1) solid; } }

@media screen and ( max-width: 1366px ) {
  .OrderDetails {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .OrderDetails-Body {
      width: 100%; }
    .Button {}
    .Info-Header {
      width: 100%;
      grid-template-columns: 1fr;
      grid-gap: 16px; } } }
