.SceneTable {
  width: 100%;
  th {
    font-size: 12px;
    padding: 8px; }
  td {
    padding: 8px; }
  tr {
    &:not(:last-child) {
      td {
        border-bottom: 1px var(--grayLight) solid; } } } }

.SceneTable-Options {
  a {
    color: var(--accentPrimary); } }
.NoInfoRow {
  width: 100%;
  text-align: center;
  color: var(--labelColor);
  td {
    padding: 8px 0px;
    width: 100%; } }
.OptionsButton-Container {
  position: relative; }
