.Auth {
  .NotificationBlock {
    margin-bottom: 16px; }
  .SignButton {
    background-color: var(--color-primary-6);
    color: #fff; } }
.AuthLinking {
  color: var(--color-primary-10); }

.SignUp, .PasswordResetForm {
  max-width: 600px; }
