.MedComissions {
  display: flex;
  justify-content: center;
  padding: 64px 0px;
  background-color: #f1f7f9; }
.MedComissions-Tiles {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  .ComissionTile {
    background-color: #fff;
    color: var(--color-primary-10);
    padding: 16px;
    border-radius: 16px;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    text-align: center;
    grid-gap: 16px;
    cursor: pointer;

    .Title {
      text-transform: uppercase;
      font-weight: 700;
 }      // font-size: 14px
    &:hover {
      background-color: var(--color-primary-11);
      color: #fff; }
    .IconContainer {
      width: 80px;
      height: 80px;
      border-radius: 100%;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 52px; } }
    a {
      align-self: end; } }
  .ComissionTile_theme_active {
    background-color: var(--color-primary-11);
    color: #fff; } }
.MedComissions-Content {
  display: grid;
  grid-template-columns: 1fr 280px;
  grid-gap: 24px;
  margin-top: 24px; }
.MedComissions-Page {
  padding: 16px;
  background-color: #fff;
  border-radius: 24px;
  margin-bottom: 48px;
  .PriceTable {
    margin-top: 24px; } }

@media screen and ( max-width: 576px ) {
  .MedComissions-Tiles {
    grid-template-columns: repeat(2, minmax(0, 1fr)); }
  .MedComissions-Content {
    grid-template-columns: 1fr; }
  .MedComissions-Page {
    margin-bottom: 0px;
    .PriceContainer {
      padding: 0 !important; } } }
