.SearchInput {
  padding: 12px 16px 12px 40px;
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: 8px;
  align-items: center;
  position: relative;
  background-color: #fff;
  border-radius: 100px;
  cursor: pointer;
  position: relative;
  &:hover {
    box-shadow: var(--shadow-l);
    z-index: 2;
    .Title {
      color: var(--color-primary-10); } }

  .SearchInput-Data {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 4px; }
  .SearchInput-DropValue {
    width: 24px;
    height: 24px;
    background-color: var(--color-tertiary-2);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    color: var(--color-tertiary-6);
    font-size: 12px;
    cursor: pointer;
    &:hover {
      background-color: var(--color-primary-10);
      color: #fff; } }

  .Placeholder {
    color: var(--color-tertiary-6); } }

.DropdownList_custom {
  width: 100%;
  padding: 20px 0px;
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  background-color: #fff;
  border-radius: 24px;
  box-shadow: var(--shadow-l);
  max-height: 430px;
  .List {
    max-height: calc(430px - 40px);
    overflow-y: scroll;
    padding: 0px 16px; } }

.ListElement {
  padding: 4px 0px;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-gap: 16px;
  align-items: center;
  .Icon-Container {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-tertiary-1);
    border-radius: 12px;
    .Icon {
      font-size: 24px;
      color: var(--color-tertiary-4); } }
  .Radio {
    margin-right: 3px;
    width: 18px;
    height: 18px;
    border: 2px var(--color-tertiary-4) solid;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
  &:hover {
    .Icon-Container {
      background-color: var(--color-primary-1);
      .Icon {
        color: var(--color-primary-10); } }
    .Radio {
      border: 2px var(--color-primary-10) solid;
      &::after {
        display: block;
        content: '';
        width: 8px;
        height: 8px;
        background-color: var(--color-primary-10);
        border-radius: 100%; } } } }
.ListElement_theme_active {
  .Icon-Container {
    background-color: var(--color-primary-1);
    .Icon {
      color: var(--color-primary-10); } }
  .Radio {
    border: 2px var(--color-primary-10) solid;
    &::after {
      display: block;
      content: '';
      width: 8px;
      height: 8px;
      background-color: var(--color-primary-10);
      border-radius: 100%; } } }
.SearchInput_with_value {
  .Title {
    color: var(--color-primary-10); }
  .Placeholder {
    color: var(--color-tertiary-10); } }
