.DoctorAvatar {
  width: 48px;
  height: 48px;
  background-color: #F1F7F9;
  border-radius: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-primary-10);
  font-size: 24px;
  img {
    object-fit: cover;
    object-position: top center;
    width: 100%;
    height: 100%;
    border-radius: 100%; }
  .Icon {
    color: var(--color-primary-10); } }
