.CartItemValued {
  display: grid;
  grid-template-columns: 80px 1fr;
  grid-gap: 16px;
  padding: 16px;
  .Avatar {
    width: 80px;
    height: 140px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center; } }
  .CartItem-Head {
    display: flex;
    align-items: center;
    margin-bottom: 16px; }
  .CartItem-Param {
    margin-left: auto; }
  .GenderIcon {
    width: 20px;
    height: 20px;
    margin-right: 16px;
    .Icon {
      font-size: 12px; } }
  .CartItemValued-Content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    align-content: start;
    .Content-Value {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .Value {
        color: var(--color-tertiary-6); } } }

  .PriceLine {
    grid-column: 1/-1;
    color: var(--color-tertiary-8);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px; } }
