.HomeSearch {
  width: 100%;
  max-width: 900px;
  background-color: #fff;
  border-radius: 100px;
  box-shadow: var(--shadow-l);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  position: relative;
  z-index: 2; }
@media screen and ( max-width: 1366px ) {
  .HomeSearch {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: var(--shadow-l);
    cursor: pointer;
    .Icon {
      color: var(--color-primary-10);
      margin-right: 8px; } } }
