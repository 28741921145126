.Home {
  display: flex;
  justify-content: center;
  flex-direction: column; }
//   .Page-Container
//   .Header
//     margin-bottom: 40px
//   .HowItWorks
//     margin-top: 168px
//     .Button
//       background-color: #fff
//       border: 1px #fff solid
//   .HomeSearch
//     margin-left: 50%
//     transform: translateX(-50%)
// .Page-Container
//   .Hero
//     padding: 0px 80px
//   .Footer
//     padding-left: 80px
//     padding-right: 80px
// .Hero
//   min-height: 100vh
//   background-size: cover
//   background-position: top center
// .Home-Body
//   height: 100%

// @media screen and ( max-width: 1366px )
//   .Page-Container
//     .Hero
//       padding: 0px 16px
//     .Footer
//       padding-left: 16px
//       padding-right: 16px
//   .Home
//     .HowItWorks
//       margin-top: 100px
//       text-align: center
