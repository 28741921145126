.DateChooser {
  .Calendar {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    background-color: #fff;
    padding: 24px;
    border-radius: 24px;
    width: 691px;
    box-shadow: var(--shadow-l); }
  .Button {
    flex-shrink: 0; }
  .Buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .Checkbox-Container {
      .Checkbox {
        width: 18px;
        height: 18px; }
      .Label {
        font-size: 14px;
        line-height: 20px; } }
    .Buttons-Container {
      align-items: center;
      display: flex;
      .Button {
        &:first-child {
          margin-right: 16px; } } } } }
