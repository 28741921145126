.GenderIcon {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center; }
.GenderIcon_theme_male {
  background-color: var(--color-functional-info-1);
  color: var(--color-functional-info-small); }
.GenderIcon_theme_female {
  background-color: var(--color-additional-pink-1);
  color: var(--color-additional-pink-10); }
.GenderIcon_theme_kid {
  background-color: var(--color-secondary-2);
  color: var(--color-secondary-10); }
