.Radio-Container {
  display: flex;
  align-items: center;
  cursor: pointer;
  .Label {
    margin-left: 8px; }
  &:hover {
    .Radio {
      border: 2px var(--color-primary-10) solid;
      background-color: var(--color-primary-1); } } }
.Radio {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 2px var(--color-tertiary-4) solid;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  .Icon {
    font-size: 10px; } }

.Radio_theme_on {
  border: 2px var(--color-primary-10) solid;
  color: var(--color-primary-10); }
