.DoctorsSlider {
  display: flex;
  justify-content: center;
  padding: 48px 0px; }

.DoctorsSlider-Container {
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-gap: 24px;
  align-items: start;
  .InfoBlock {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    color: var(--color-tertiary-8); } }
.DoctorsCarousel {
  width: 902px;
  // padding: 12px 0px
  // display: grid
  // grid-template-columns: repeat(4, minmax(0, 1fr))
 }  // grid-gap: 24px

@media screen and ( max-width: 576px ) {
  .DoctorsSlider-Container {
    grid-template-columns: 1fr;
    .InfoBlock {
      order: 1;
      justify-content: center;
      text-align: center; } }
  .DoctorsCarousel {
    width: 345px;
    order: 0; } }
