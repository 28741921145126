.CityChooserPopUp {
  max-width: 500px;
  .Button-State_active {
    border: 1px var(--color-primary-8) solid;
    background-color: var(--color-primary-2);
    color: var(--color-tertiary-10); }
  .Buttons {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    .Button {
      &:not(:first-child) {
        margin-left: 16px; } } }
  .Cities-Container {
    display: flex;
    .CityTile {
      margin: 8px 4px 0; } } }
