.DatePickerPopUp {
  min-width: 707px; }
.DatePickerPopUp-Container {
  .Buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .Checkbox-Container {
      .Checkbox {
        width: 18px;
        height: 18px; }
      .Label {
        font-size: 14px;
        line-height: 20px; } }
    .Buttons-Container {
      align-items: center;
      display: flex;
      .Button {
        &:first-child {
          margin-right: 16px; } } } } }
