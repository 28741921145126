.NotificationBlock {
  padding: 16px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 24px 1fr;
  grid-gap: 16px;
  .Icon-Container {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #fff; }
  .Title {
    margin-bottom: 8px; } }
.NotificationBlock_theme_info {
  background-color: var(--color-functional-info-1);
  .Icon-Container {
    background-color: var(--color-functional-info-small); } }
.NotificationBlock_theme_error {
  background-color: var(--color-functional-error-1);
  .Icon-Container {
    background-color: var(--color-functional-error-regular); } }
.NotificationBlock_theme_success {
  background-color: var(--color-functional-success-1);
  .Icon-Container {
    background-color: var(--color-functional-success-regular); } }
