.Stepper {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  position: relative;
  align-items: start; }
.StepBar {
  width: 100%;
  height: 2px;
  background-color: var(--color-tertiary-2);
  position: absolute; }
.Step {
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: -8px;
  .Marker {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    border: 2px var(--color-tertiary-2) solid;
    margin-bottom: 4px;
    background-color: #fff;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center; }
  .Icon {
    font-size: 6px;
    color: #fff; }
  .Title {
    text-align: center; } }

.FirstStep {
  align-items: flex-start; }
.LastStep {
  display: flex;
  flex-direction: column;
  align-items: flex-end; }
.Step_status_notActive {
  .Title {
    color: var(--color-tertiary-4); } }

.Step_status_done {
  .Marker {
    background-color: var(--color-functional-success-regular);
    border: 2px var(--color-functional-success-regular) solid;
    box-shadow: var(--shadow-l); }
  .Title {
    color: var(--color-functional-success-regular); } }
.Step_status_active {
  .Marker {
    background-color: #fff;
    border: 4px var(--color-functional-success-regular) solid;
    box-shadow: var(--shadow-l); }
  .Title {
    color: var(--color-functional-success-regular); } }
