.OptionsList {
  padding: 0px 8px; }

.OptionsList-Element {
  width: 100%;
  padding: 6px 0px;
  display: flex;
  align-items: center;
  min-height: 30px;
  transition: all .2s ease;
  text-transform: none;
  font-weight: 400;
  color: var(--blackStandart);
  cursor: pointer;
  span {
    font-size: 12px;
    line-height: 16px; }
  &:hover {
    color: var(--accentPrimary);
    .OptionsList-ElementIcon {
      color: var(--accentPrimary); } } }

.OptionsList-ElementIcon {
  width: 20px;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  margin-right: 8px;
  text-align: center;
  color: var(--grayLight); }
