.AnalyseButton {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-tertiary-6);
  .Icon {
    font-size: 24px;
    color: var(--color-primary-10);
    margin-bottom: 6px; }
  .Avatar {
    cursor: pointer; } }
