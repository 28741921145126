.StatusMessage {
  padding: 8px 16px;
  display: flex;
  align-items: center; }

.StatusMessage_theme_success {
  background: var(--color-primary-2);
  color: var(--color-functional-success-regular); }

.StatusMessage_theme_fail {
  background: var(--color-additional-pink-1);
  color: var(--color-additional-pink-10); }
