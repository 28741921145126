.PriceSection {
  margin-top: 24px;
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px var(--color-tertiary-2) solid;
  display: grid;
  grid-template-columns: 1fr 350px;
  grid-gap: 24px;
  align-items: start;
  .CartOnPage {
    background-color: rgba(195,223,230,.2); } }

@media screen and ( max-width: 576px ) {
  .PriceSection {
    grid-template-columns: 1fr;
    padding: 0;
    .CartOnPage {
      display: none; } } }
