.CartItem {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  .CartItem-Head {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .GenderIcon {
    width: 20px;
    height: 20px;
    margin-right: 16px;
    .Icon {
      font-size: 12px; } }
  .CartItem-Content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    align-content: start;
    .Position {
      display: grid;
      grid-template-columns: 60px 1fr;
      grid-gap: 24px;
      align-items: center;
      .Avatar {
        width: 60px;
        height: 80px;
        background-color: #fff;
        span {
          width: 100%;
          height: 100%; }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: top center; } }
      .Data {
        color: var(--color-tertiary-8); } } }
  .PriceLine {
    color: var(--color-tertiary-8);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px; } }
