.About {
  width: 100%;
  background-color: #f1f7f9;
  display: flex;
  justify-content: center;
  padding: 64px 0px; }
.About-Container {
  display: grid;
  grid-template-columns: 500px 1fr;
  grid-gap: 32px; }
.AboutVideo {
  width: 100%;
  height: 300px; }
.AboutImg {

  object-fit: cover;
  object-position: center center; }
.About-Text {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  color: var(--color-tertiary-8);
  .BenefitsList {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    margin-bottom: 16px;
    .InfoLine {
      .Icon {
        color: var(--color-primary-10); } } } }

@media screen and ( max-width: 576px ) {
  .About-Container {
    grid-template-columns: 1fr; }
  .About-Buttons {
    display: flex;
    justify-content: center;
    .Button {
      margin-left: auto; } } }
