.PaymentMethods {
  .Buttons {
    margin-top: 24px; }
  .NotificationBlock {
    margin-bottom: 24px; } }

.PaymentCard {
  display: flex;
  align-items: center;
  padding: 16px 4px;
  &:not(:last-child) {
    border-bottom: 1px var(--color-tertiary-1) solid; }
  .Card-Data {
    margin-left: 32px;
    display: flex;
    align-items: center;
    width: 100%;
    .CardImg {
      margin-right: 16px; }
    .DefaultCard {
      color: var(--color-primary-10);
      margin-bottom: 4px; }
    .CardNumber {
      color: var(--color-tertiary-8);
      margin-top: 4px; } }
  .DeleteIcon {
    color: var(--color-primary-10);
    margin-left: auto;
    font-size: 20px;
    line-height: 20px;
    cursor: pointer; } }
