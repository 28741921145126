.ScheduleTiles {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  grid-gap: 24px;
  .ScheduleGroup {}
  .ScheduleGroup-Header {
    display: grid;
    grid-template-columns: repeat(4, max-content);
    grid-gap: 16px;
    padding: 8px 16px;
    border: 1px var(--color-tertiary-1) solid;
    width: 100%;
    border-radius: 10px;
    align-items: center;
    .Schedule-Title {
      color: var(--color-primary-10);
      font-weight: 700; }
    .Schedule-Description {
      color: var(--color-tertiary-6); }
    .Black {
      color: #1E2123; } }
  .ScheduleGroup-Body {
    display: none;
    padding: 16px;
    border-radius: 8px;
    border: 1px var(--color-tertiary-1) solid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 16px; }

  .Spoiler_status_expanded {
    .ScheduleGroup-Header {
      background-color: rgba(195,223,230,.2);
      border: 1px var(--color-primary-8) solid;
      box-shadow: var(--shadow-m);
      .Icon {
        transform: rotate(180deg); } }
    .ScheduleGroup-Body {
      display: grid;
      margin-top: 16px; } } }

@media screen and ( max-width: 576px ) {
  .ScheduleTiles {
    .ScheduleGroup-Header {
      width: 100%;
      grid-template-columns: 16px 1fr;
      .Schedule-Description {
        width: 100%;
        grid-column: 1/-1; } }
    .ScheduleGroup-Body {
      grid-template-columns: repeat(2, minmax(0, 1fr)); } } }
