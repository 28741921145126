.Services {
  display: flex;
  justify-content: center;
  padding: 64px 0px;
  background-color: #f1f7f9;
  .Services-Content {
    display: grid;
    grid-template-columns: 1fr 350px;
    grid-gap: 24px; } }

.CartOnPage {
  align-self: start; }
.ProductInCart {
  box-shadow: none !important;
  .Button-IconContainer {
    background-color: var(--color-tertiary-4) !important; } }
.PriceContainer {
  align-self: start;
  padding: 16px;
  background-color: #fff;
  border-radius: 24px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  .SearchBar {
    padding: 0px 16px; }
  .SearchResult {
    text-align: center;
    font-size: 14px;
    color: var(--color-tertiary-4); }
  .NotificationText {
    padding: 16px;
    background-color: #fff3ca;
    border-radius: 8px;
    font-size: 14px;
    margin-bottom: 16px;
    .Icon {
      font-size: 16px;
      margin-right: 12px; } }
  .CategoryHeader {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px 0px;
    .Icon {
      font-size: 18px;
      margin-right: 12px;
      color: var(--color-tertiary-4); }
    .Title {
      color: var(--color-primary-10); } }
  .CategoryBody {
    padding-left: 18px;
    padding-top: 12px;
    padding-bottom: 12px;
    display: none; }
  .Spoiler_status_expanded {
    .CategoryBody {
      display: block; }
    .CategoryHeader {
      .Icon {
        transform: rotate(90deg); } } } }

@media screen and ( max-width: 576px ) {
  .Services {
    .Services-Content {
      grid-template-columns: 1fr; }
    .CartOnPage {
      display: none; } } }
