.Checkbox-Container {
  display: flex;
  align-items: center;
  cursor: pointer;
  .Label {
    margin-left: 8px; }
  &:hover {
    .Checkbox {
      border: 2px var(--color-primary-10) solid;
      background-color: var(--color-primary-1); } } }
.Checkbox {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 2px var(--color-tertiary-4) solid;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

.Checkbox_theme_indeterminated {
  background-color: var(--color-primary-10);
  border: 2px var(--color-primary-10) solid;
  color: #fff; }
