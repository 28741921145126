.DropdownBox-Container {
  position: absolute;
  width: 100%;
  z-index: 3;
  padding-top: 12px;
  right: 0px; }

.DropdownBox {
  padding: 4px;
  background-color: #FFF;
  max-height: 300px;
  overflow: auto;
  border-radius: 2px;
  box-shadow: var(--shadow-m); }
.DropdownBox-EmptyText {
  color: var(--darkGray);
  text-align: center; }

.DropdownBox-Element {
  position: relative;
  list-style: none;
  cursor: pointer;
  color: var(--color-tertiary-10);
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  padding: 4px 8px;
  .Label {
    font-size: 12px;
    line-height: normal;
    color: #000;
    font-weight: 700;
    text-align: left;
    margin-bottom: 4px;
    .SubLabel, .SubLabel-Gray {
      font-size: 12px;
      line-height: normal;
      text-align: left;
      font-weight: 500;
      color: var(--darkGray); }
    .SubLabel-Gray {
      color: var(--darkGray); } }

  &:hover {
    color: var(--color-primary-11);
    background: var(--color-primary-1);
    .Label, .SubLabel, .SubLabel-Gray {
      color: var(--color-primary-11); } }
  &:after {
    content: '';
    position: absolute;
    width: calc(100% - 16px);
    background-color: var(--lighGray);
    height: 1px;
    bottom: -1px;
    left: 8px; }
  &:last-child {
    &:after {
      display: none; } }
  &:not(:last-child) {
    border-bottom: 1px var(--lightGray) solid; } }

.DropdownBox-Element_theme_active {
  color: #fff;
  background: var(--accent);
  .Label, .SubLabel, .SubLabel-Gray {
    color: #fff; } }

.DropdownBox {
  svg {
    transform: rotate(0deg); }
  .EmptyMessage {
    margin: 0;
    text-align: center;
    color: var(--lighGray); }
  .Field {
    margin: 8px;
    input {
      padding: 8px 8px; }
    label {
      top: 16px;
      left: 8px; } }
  .Field_with_value {
    label {
      top: -8px;
      left: 8px; } } }
