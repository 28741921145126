.DoctorTile {
  padding: 16px;
  // border: 1px #000 solid
  border-radius: 16px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  border: 1px #fff solid;
  cursor: pointer;
  background: #fff;
  align-content: start;
  &:hover {
    box-shadow: var(--shadow-m);
    border: 1px var(--color-primary-8) solid; }

  .Title {
    color: var(--color-primary-10);
    font-weight: 600; }
  .Doctor-Avatar {
    width: 100%;
    height: 200px;
    background-color: #F1F7F9;
    border-radius: 16px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-primary-10);
    font-size: 32px;
    margin-bottom: 12px;
    img {
      object-fit: cover;
      object-position: top center;
      width: 100%;
      height: 100%;
      border-radius: 16px; } }
  .Stage {
    margin-top: 12px; }
  .StageCounter {
    color: var(--color-primary-10); }
  .Description {
    color: var(--color-tertiary-4); } }
