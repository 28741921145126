.EditProfile {
  .NotificationBlock {
    margin-bottom: 24px; } }
.EditProfile-MainForm {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 24px; }
.EditProfile-FastOrder {
  background-color: var(--color-tertiary-04);
  padding: 16px;
  border-radius: 8px;
  .Title {
    display: flex;
    align-items: center;
    color: var(--color-tertiary-6);
    margin-bottom: 24px;
    .Icon {
      margin-right: 8px; } }
  .Fields {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 16px; } }
.EditProfile-About {
  margin-top: 24px;
  padding-top: 24px;
  .Fields {
    margin-top: 16px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 24px; } }

@media screen and ( max-width: 1366px ) {
  .EditProfile {
    h2 {
      display: none !important; }
    .EditProfile-MainForm {
      grid-template-columns: 1fr; }
    .EditProfile-FastOrder, .EditProfile-About {
      .Fields {
        grid-template-columns: 1fr; } }
    .Mobile-SaveProfile {
      width: 100%;
      margin-top: 24px; } } }
