.SuccessCheckout {
  justify-items: center;
  padding: 48px 0px;
  flex-direction: column;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;
  .ImageContainer {
    width: 500px;
    img {
      width: 500px; } }
  .SectionTitle {
    margin-bottom: 0;
    &::after {
      display: none; } } }

@media screen and ( max-width: 576px ) {
  .SuccessCheckout {
    padding: 48px 16px;
    .ImageContainer {
      width: 100%;
      img {
        width: 100%; } } } }
