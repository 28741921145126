.PaymentCardPopUp {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: #fff;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  padding: 24px 80px;
  overflow-y: auto;
  .CardForm-Container {
    display: flex;
    align-items: center;
    justify-content: center; }
  .CardForm {
    max-width: 360px; }
  .Title {
    margin-top: 24px;
    padding-left: 24px; } }

@media screen and ( max-width: 1366px ) {
  .PaymentCardPopUp {
    padding: 24px 16px; } }
