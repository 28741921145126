.PasswordRecoveryPage {
  display: grid;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 56px);
  position: relative;
  .Text {
    margin: 32px 0px;
    .Body-Regular-2 {
      color: var(--color-tertiary-8);
      margin-top: 16px; } }
  .SupportMessage {
    position: absolute;
    bottom: -24px;
    left: 50%;
    transform: translateX(-50%);
    color: var(--color-tertiary-8);
    a {
      color: var(--color-primary-10); } } }
.PasswordRecoveryPage-Container {
  text-align: center;
  .Buttons {
    display: inline-grid;
    grid-template-columns: 160px;
    grid-gap: 16px; } }
