.Health {
  .Health-Main {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 24px; }
  .HealtMain-Tile {
    padding: 16px 24px;
    border-radius: 8px;
    padding-bottom: 64px;
    .Title {
      font-size: 16px;
      font-weight: 600; } }
  .Health-EventList {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
    margin-top: 24px; } }

@media screen and ( max-width: 1366px ) {
  .Orders {
    h2 {
      display: none; }
    .TabGroup {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      .Tab {
        text-align: center; } }
    .Buttons {
      width: 100%;
      .Button {
        width: 100%; } } } }
@media screen and ( max-width: 576px ) {
  .Health {
    .Health-Main {
      grid-template-columns: 1fr;
      grid-gap: 24px; } } }
