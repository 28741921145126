.GenderOptions {}

.GenderOptions-Container {
  margin-top: 8px;
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-gap: 12px;
  .Option {
    background-color: var(--color-tertiary-04);
    color: var(--color-tertiary-6);
    padding: 0px 16px;
    display: flex;
    align-items: center;
    height: 32px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    border-radius: 16px;
    border: 1px var(--color-tertiary-1) solid;
    cursor: pointer;
    .Icon {
      margin-right: 8px; } }
  .Option_theme_active {
    background-color: var(--color-primary-10);
    color: #fff;
    border: 1px var(--color-primary-10) solid; } }
