.OrdersTable {
  th {
    padding: 4px 16px;
    background-color: var(--color-tertiary-04);
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: var(--color-tertiary-6);
    border-bottom: 1px var(--color-tertiary-1) solid; }
  td {
    padding: 16px;
    border-bottom: 1px var(--color-tertiary-1) solid; }
  tbody {
    tr {
      &:hover {
        td {
          cursor: pointer;
          background-color: var(--color-tertiary-04); }
        .OrderCity {
          color: var(--color-primary-10); } } } }
  .OrderCity {
    font-size: 16px;
    line-height: 24px;
    color: var(--color-tertiary-10);
    font-weight: 700; } }

.OrderStatus {
  padding: 4px 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center; }
