.CartOnPage {
  padding: 32px 16px;
  background-color: #fff;
  border-radius: 24px;
  .CartOnPage-Body {
    margin-top: 24px; }
  .CartItem {
    display: grid;
    grid-template-columns: 1fr max-content 16px;
    border-bottom: 1px var(--color-tertiary-1) solid;
    padding: 4px 0px;
    .TimesButton {
      background-color: var(--color-additional-pink-1);
      width: 16px;
      height: 16px;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      color: #fff;
      border-radius: 100%;
      cursor: pointer; } }
  .CartOnPage-Total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0px;
    .Total {
      font-size: 16px;
      font-weight: 700; }
    .Price {
      font-size: 16px;
      font-weight: 500; } } }
.CartOnPage-Button {
  background-color: var(--color-primary-8);
  color: #fff;
  .Button-IconContainer {
    background-color: #fff;
    .Icon {
      color: var(--color-primary-6) !important; } } }
.CartOnPage-Header {
  display: flex;
  align-items: center;
  .Icon {
    color: var(--color-primary-8);
    font-size: 24px; }
  .Title {
    font-size: 18px;
    font-weight: 700;
    margin-left: 16px; } }
