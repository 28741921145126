.PasswordReset {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 0px;
  border-top: 1px var(--color-tertiary-1) solid;
  border-bottom: 1px var(--color-tertiary-1) solid;
  margin-top: 32px;
  margin-bottom: 24px;
  .PasswordReset-F {
    .Content {
      margin-top: 8px;
      display: flex;
      align-items: center;
      font-size: 14px;
      .Icon {
        font-size: 16px;
        color: var(--color-tertiary-6);
        margin-right: 8px; } } }
  .EditIcon {
    color: var(--color-primary-10); } }
