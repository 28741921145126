.EventTile {
  padding: 16px;
  border: 1px #DEEAED solid;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    border: 1px var(--color-primary-8) solid;
    box-shadow: var(--shadow-m); }

  .PayOnlineButton {
    height: 32px;
    padding: 4px 8px;
    color: #fff;
    background-color: #1AAA55;
    border: 0px; }
  .Title-Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    .Title {
      color: var(--color-primary-10);
      font-size: 18px;
      font-weight: 700; }
    .Date {
      font-size: 12px; } }
  .EventTile-Details {}
  .Details-List {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px; }

  .Content {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr)) max-content;
    grid-gap: 24px;
    align-items: start; }
  .DoctorInfo {
    display: grid;
    grid-template-columns: 42px 1fr;
    grid-gap: 12px; }
  .ClinicAddress {
    color: var(--color-tertiary-6); }
  .Total {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .Total-Data {
    display: flex;
    align-items: center;
    .Status {
      margin-left: 16px; } }
  .Total-Controlls {
    display: flex;
    align-items: center;
    .Button {
      &:not(:last-child) {
        margin-right: 16px; } } } }

@media screen and ( max-width: 576px ) {
  .EventTile {
    .Title-Header {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 12px; }
    .Content {
      grid-template-columns: 1fr; } } }
