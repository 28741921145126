.PickupPointMobile {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr max-content;
  background-color: var(--color-tertiary-04);
  overflow-y: auto;
  .CloseButton {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: #fff;
    z-index: 1000;
    top: 16px;
    right: 16px;
    border-radius: 100%;
    color: var(--color-tertiary-8);
    box-shadow: var(--shadow-l); }
  .LocationsMap {
    display: block;
    height: 100%;
    margin-top: 0; }
  .Buttons {
    margin-top: 24px;
    width: 100%;
    .Button {
      width: 100%; } }
  .PointTile {
    background-color: #fff;
    padding: 16px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    box-shadow: var(--shadow-l);
 } }    // padding-bottom: 64px
