.DoctorsPage {
  display: flex;
  justify-content: center;
  padding: 48px 0px;
  background-color: #f1f7f9;
  .Doctors-Content {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-gap: 24px; }
  .DoctorTile {
    .Doctor-Avatar {
      background-color: #fff; } } }

@media screen and ( max-width: 576px ) {
  .DoctorsPage {
    .Doctors-Content {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: 16px; } } }
