.SectionTitle {
  font-size: 32px;
  font-weight: 300;
  margin-bottom: 48px;
  color: var(--color-primary-10);
  span {
    font-weight: 700; }
  &:after {
    content: '';
    display: block;
    width: 64px;
    height: 2px;
    border-radius: 4px;
    margin-top: 16px;
    background: var(--color-primary-6); } }
