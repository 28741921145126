.ProfilePopUp {
  background-color: #fff;
  min-width: 280px;
  z-index: 1000;
  position: absolute;
  top: 64px;
  right: -32px;
  box-shadow: var(--shadow-l);
  .UnsignedUser {
    padding: 16px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    .Buttons {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 8px; } }
  .UserProfilePopUp {
    padding: 8px 0px;
    box-shadow: var(--shadow-l);
    .Profile-Credentials {
      display: grid;
      grid-template-columns: 32px 1fr 8px;
      grid-gap: 16px;
      align-items: center;
      padding: 6px 16px;
      color: var(--color-tertiary-10);
      position: relative;
      .AngleRight {
        display: none; }
      .Icon {
        font-size: 16px; }
      &:hover {
        background-color: var(--color-primary-1);
        .AngleRight {
          display: block; }
        .UserName, .Icon {
          color: var(--color-primary-10); } }
      &:active {
        background: none;
        .UserName, .Icon {
          color: var(--color-primary-10); }
        .AngleRight {
          display: none; }
        &::before {
          display: block;
          content: '';
          position: absolute;
          width: 3px;
          height: 100%;
          background-color: var(--color-primary-10);
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          top: 0;
          left: 0; } } }
    .Profile-Links {
      margin: 8px 0px;
      border-top: 1px var(--color-tertiary-1) solid;
      border-bottom: 1px var(--color-tertiary-1) solid;
      padding: 8px 0px; }
    // .ProfileNavItem
    //   &::before
    //     display: none !important
    //   &:hover
    //     &::before
    //       display: none !important
    .Profile-SignOut {
      .ProfileNavItem {
        color: var(--color-functional-error-small);
        .Icon {
          color: var(--color-functional-error-small); } } } } }
@media screen and ( max-width: 576px ) {
  .ProfilePopUp {
    right: -96px;
    min-width: 100vw; } }
