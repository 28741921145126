/*! build commithash 5370309ae1e6fadb4ee1b5d141457f80c0ef1a2b Thu, 10 Dec 2020 10:10:19 GMT */
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://pay.fondy.eu/icons/dist/fonts/inter-regular.woff2)
      format('woff2'),
    url(https://pay.fondy.eu/icons/dist/fonts/inter-regular.woff) format('woff');
}
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://pay.fondy.eu/icons/dist/fonts/inter-medium.woff2)
      format('woff2'),
    url(https://pay.fondy.eu/icons/dist/fonts/inter-medium.woff) format('woff');
}
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://pay.fondy.eu/icons/dist/fonts/inter-semibold.woff2)
      format('woff2'),
    url(https://pay.fondy.eu/icons/dist/fonts/inter-semibold.woff)
      format('woff');
}
@font-face {
  font-family: Card Number;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://pay.fondy.eu/icons/dist/fonts/card-number.woff)
    format('woff');
}
@font-face {
  font-family: Cvv;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://pay.fondy.eu/icons/dist/fonts/cvv.woff) format('woff');
}
#f *,
#f :after,
#f :before {
  box-sizing: border-box;
}
#f [tabindex='-1']:focus:not(:focus-visible) {
  outline: 0 !important;
}
#f h1,
#f h2,
#f h3,
#f h4,
#f h5,
#f h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
#f p {
  margin: 0.5rem 0;
}
#f ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
#f a {
  font-weight: 600;
  color: var(--fondy-link_color);
  text-decoration: none;
  background-color: transparent;
}
#f a:hover {
  text-decoration: underline;
}
#f a:focus {
  outline: 0;
}
#f a:not([href]),
#f a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}
#f img {
  border-style: none;
}
#f img,
#f svg {
  vertical-align: middle;
}
#f svg {
  overflow: hidden;
}
#f table {
  border-collapse: collapse;
}
#f th {
  text-align: inherit;
}
#f label {
  display: inline-block;
  margin-bottom: 0;
}
#f button {
  border-radius: 0;
}
#f button:focus {
  outline: 0;
}
#f button,
#f input,
#f select {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
#f button,
#f input {
  overflow: visible;
}
#f button,
#f select {
  text-transform: none;
}
#f [role='button'] {
  cursor: pointer;
}
#f select {
  word-wrap: normal;
}
#f [type='button'],
#f [type='submit'],
#f button {
  -webkit-appearance: button;
}
#f [type='button']:not(:disabled),
#f [type='submit']:not(:disabled),
#f button:not(:disabled) {
  cursor: pointer;
}
#f [type='button']::-moz-focus-inner,
#f [type='submit']::-moz-focus-inner,
#f button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
#f input[type='checkbox'],
#f input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
#f [type='number']::-webkit-inner-spin-button,
#f [type='number']::-webkit-outer-spin-button {
  height: auto;
}
#f ::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
#f .fade {
  transition: opacity 0.15s linear;
}
#f .fade:not(.show) {
  opacity: 0;
}
#f .collapse-enter-active,
#f .collapse-leave-active {
  max-height: 100vh;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}
#f .collapse-enter,
#f .collapse-leave-to {
  max-height: 0;
  overflow: hidden;
}
#f .fade-enter-enter-active {
  transition: opacity 0.3s ease-in-out;
}
#f .fade-enter-enter {
  opacity: 0;
}
#f .fade-enter-leave-active {
  display: none;
}
#f .fade-enter-active,
#f .fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}
#f .fade-enter,
#f .fade-leave-to {
  opacity: 0;
}
#f .slide-fade-enter-active {
  transition: all 0.3s ease;
}
#f .slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
#f .slide-fade-enter,
#f .slide-fade-leave-to {
  opacity: 0;
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
}
#f .f-datepicker-zoom-in-down-enter-active,
#f .f-datepicker-zoom-in-down-leave-active {
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1),
    opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1),
    opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transform-origin: center top;
  transform-origin: center top;
}
#f .f-datepicker-zoom-in-down-enter,
#f .f-datepicker-zoom-in-down-leave-to {
  opacity: 0;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
}
#f .f-title {
  margin: 0;
  margin-bottom: 1.25rem;
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  color: var(--fondy-title_color);
}
#f .f-title-lg {
  font-size: 1.5rem;
  font-weight: 600;
}
#f .f-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  word-wrap: break-word;
  outline: 0;
}
#f .f-tooltip .f-tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.625rem;
  height: 0.4375rem;
  margin: 0 0.5rem;
}
#f .f-tooltip .f-tooltip-arrow:before {
  position: absolute;
  content: '';
  border-color: transparent;
  border-style: solid;
}
#f .f-tooltip.noninteractive {
  pointer-events: none;
}
#f .f-tooltip.f-tooltip-auto[x-placement^='left'] .f-tooltip-arrow,
#f .f-tooltip.f-tooltip-auto[x-placement^='right'] .f-tooltip-arrow,
#f .f-tooltip.f-tooltip-left .f-tooltip-arrow,
#f .f-tooltip.f-tooltip-right .f-tooltip-arrow {
  margin: 0.5rem 0;
}
#f .f-tooltip-under-sticky {
  z-index: 1019;
}
#f .f-tooltip-auto[x-placement^='top'],
#f .f-tooltip-top {
  padding: 0.4375rem 0;
}
#f .f-tooltip-auto[x-placement^='top'] .f-tooltip-arrow,
#f .f-tooltip-top .f-tooltip-arrow {
  bottom: 0;
}
#f .f-tooltip-auto[x-placement^='top'] .f-tooltip-arrow:before,
#f .f-tooltip-top .f-tooltip-arrow:before {
  top: 0;
  border-width: 0.4375rem 0.3125rem 0;
}
#f .f-tooltip-auto[x-placement^='right'],
#f .f-tooltip-right {
  padding: 0 0.4375rem;
}
#f .f-tooltip-auto[x-placement^='right'] .f-tooltip-arrow,
#f .f-tooltip-right .f-tooltip-arrow {
  left: 0;
  width: 0.4375rem;
  height: 0.625rem;
}
#f .f-tooltip-auto[x-placement^='right'] .f-tooltip-arrow:before,
#f .f-tooltip-right .f-tooltip-arrow:before {
  right: 0;
  border-width: 0.3125rem 0.4375rem 0.3125rem 0;
}
#f .f-tooltip-auto[x-placement^='bottom'],
#f .f-tooltip-bottom {
  padding: 0.4375rem 0;
}
#f .f-tooltip-auto[x-placement^='bottom'] .f-tooltip-arrow,
#f .f-tooltip-bottom .f-tooltip-arrow {
  top: 0;
}
#f .f-tooltip-auto[x-placement^='bottom'] .f-tooltip-arrow:before,
#f .f-tooltip-bottom .f-tooltip-arrow:before {
  bottom: 0;
  border-width: 0 0.3125rem 0.4375rem;
}
#f .f-tooltip-auto[x-placement^='left'],
#f .f-tooltip-left {
  padding: 0 0.4375rem;
}
#f .f-tooltip-auto[x-placement^='left'] .f-tooltip-arrow,
#f .f-tooltip-left .f-tooltip-arrow {
  right: 0;
  width: 0.4375rem;
  height: 0.625rem;
}
#f .f-tooltip-auto[x-placement^='left'] .f-tooltip-arrow:before,
#f .f-tooltip-left .f-tooltip-arrow:before {
  left: 0;
  border-width: 0.3125rem 0 0.3125rem 0.4375rem;
}
#f .f-tooltip-inner {
  display: flex;
  width: 100%;
  max-width: 18.75rem;
  padding: 1rem;
  font-weight: 500;
  border-radius: 0.5rem;
}
#f .f-tooltip-inner .f-svg {
  margin: 0.125rem 0.5rem 0 0;
}
#f .f-tooltip-dark.f-tooltip-auto[x-placement^='top'] .f-tooltip-arrow:before,
#f .f-tooltip-dark.f-tooltip-top .f-tooltip-arrow:before {
  border-top-color: var(--fondy-tooltip_dark_bg);
}
#f .f-tooltip-dark.f-tooltip-auto[x-placement^='right'] .f-tooltip-arrow:before,
#f .f-tooltip-dark.f-tooltip-right .f-tooltip-arrow:before {
  border-right-color: var(--fondy-tooltip_dark_bg);
}
#f
  .f-tooltip-dark.f-tooltip-auto[x-placement^='bottom']
  .f-tooltip-arrow:before,
#f .f-tooltip-dark.f-tooltip-bottom .f-tooltip-arrow:before {
  border-bottom-color: var(--fondy-tooltip_dark_bg);
}
#f .f-tooltip-dark.f-tooltip-auto[x-placement^='left'] .f-tooltip-arrow:before,
#f .f-tooltip-dark.f-tooltip-left .f-tooltip-arrow:before {
  border-left-color: var(--fondy-tooltip_dark_bg);
}
#f .f-tooltip-dark .f-tooltip-inner {
  background-color: var(--fondy-tooltip_dark_bg);
  color: var(--fondy-tooltip_dark_color);
  border-radius: 0.375rem;
  opacity: 0.95;
}
#f .f-tooltip-light.f-tooltip-auto[x-placement^='top'] .f-tooltip-arrow:before,
#f .f-tooltip-light.f-tooltip-top .f-tooltip-arrow:before {
  border-top-color: var(--fondy-tooltip_light_bg);
}
#f
  .f-tooltip-light.f-tooltip-auto[x-placement^='right']
  .f-tooltip-arrow:before,
#f .f-tooltip-light.f-tooltip-right .f-tooltip-arrow:before {
  border-right-color: var(--fondy-tooltip_light_bg);
}
#f
  .f-tooltip-light.f-tooltip-auto[x-placement^='bottom']
  .f-tooltip-arrow:before,
#f .f-tooltip-light.f-tooltip-bottom .f-tooltip-arrow:before {
  border-bottom-color: var(--fondy-tooltip_light_bg);
}
#f .f-tooltip-light.f-tooltip-auto[x-placement^='left'] .f-tooltip-arrow:before,
#f .f-tooltip-light.f-tooltip-left .f-tooltip-arrow:before {
  border-left-color: var(--fondy-tooltip_light_bg);
}
#f .f-tooltip-light .f-tooltip-inner {
  background-color: var(--fondy-tooltip_light_bg);
  box-shadow: 0 8px 32px
    hsla(
      var(--fondy-tooltip_light_shadow-h),
      var(--fondy-tooltip_light_shadow-s),
      var(--fondy-tooltip_light_shadow-l),
      0.5
    );
}
#f .f-form-group {
  margin-bottom: 1rem;
}
#f .f-form-group,
#f .f-form-group-inner {
  position: relative;
}
#f .f-form-group-inner-select {
  background-color: var(--fondy-input_bg);
  border-radius: 0.5rem;
}
#f .f-form-control {
  display: block;
  width: 100%;
  height: 3.5rem;
  padding: 1.625rem 0.75rem 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  color: var(--fondy-input_color);
  background-color: var(--fondy-input_bg);
  border: 1px solid var(--fondy-input_border);
  border-radius: 0.5rem;
  transition: color 0.15s ease-in-out;
}
#f .f-form-control:-webkit-autofill,
#f .f-form-control:-webkit-autofill:active,
#f .f-form-control:-webkit-autofill:focus,
#f .f-form-control:-webkit-autofill:hover {
  -webkit-transition: background-color 99999s;
  transition: background-color 99999s;
}
#f .f-form-control:-webkit-autofill:first-line {
  font-size: 1rem;
  font-weight: 500;
  color: var(--fondy-input_color);
}
#f .f-form-control:hover {
  background-color: var(--fondy-input_hover_bg);
  border-color: var(--fondy-input_hover_border);
}
#f .f-form-control:focus {
  border-color: var(--fondy-input_focus_border);
  border-width: 0.09375rem;
  outline: 0;
}
#f .f-disabled > .f-form-control,
#f .f-form-control[disabled] {
  color: var(--fondy-input_readonly_color);
  border-color: var(--fondy-input_border);
  -webkit-text-fill-color: var(--fondy-input_readonly_color);
  opacity: 1;
}
#f .f-form-control:-webkit-autofill + .f-control-label-p {
  -webkit-transform: translateY(-8px) scale(0.75);
  transform: translateY(-8px) scale(0.75);
}
#f .f-has-error .f-form-control {
  color: var(--fondy-input_error_color);
  border: 2px solid var(--fondy-input_error_border);
}
#f .f-has-error .f-form-control:focus {
  border-color: var(--fondy-input_focus_border);
  border-width: 0.09375rem;
}
#f .f-form-control[type='date'],
#f .f-form-control[type='tel'],
#f .f-form-control[type='text'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
#f .f-control-sm {
  height: 2.75rem;
  padding: 0.9375rem 0.75rem 0.3125rem;
}
#f .f-form-item-sm + .f-control-label-p {
  top: 0.75rem;
}
#f .f-form-control-select-wrapper {
  position: relative;
}
#f .f-form-control-select {
  position: relative;
  z-index: 1;
  padding-right: 1.5rem;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
#f .f-form-control-select::-ms-expand {
  display: none;
}
#f .f-form-control-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--fondy-input_color);
}
#f .f-form-control-select:hover {
  background-color: inherit;
}
#f .f-form-control-select[disabled] {
  opacity: 1;
}
#f .f-form-control-select > option {
  color: initial;
}
#f .f-form-control-select + .f-svg {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
#f .f-form-control-description {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
}
#f .f-control-label {
  font-size: 1rem;
  color: var(--fondy-label_color);
}
#f .f-control-label-p {
  position: absolute;
  top: 1.0625rem;
  right: 0;
  left: 0.75rem;
  transition: -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#f .f-control-label-p.f-control-label-active {
  -webkit-transform: translateY(-8px) scale(0.75);
  transform: translateY(-8px) scale(0.75);
}
#f .f-form-group-amount .f-control-label-p {
  padding-right: 2.5rem;
}
#f .f-control-label-hover {
  color: var(--fondy-label_hover_color);
}
#f .f-control-label-focused {
  color: var(--fondy-label_focus_color);
}
#f .f-disabled > .f-control-label,
#f .f-form-control[disabled] + .f-control-label {
  color: var(--fondy-label_color);
}
#f .f-error {
  font-weight: 500;
  color: var(--fondy-text_error_color);
}
#f .f-form-control-prepend {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  margin: 0;
  color: var(--fondy-input_prepend_color);
}
#f .f-form-control-prepend + div .f-form-control {
  padding-left: 2.5rem;
}
#f .f-form-control-prepend ~ .f-control-label-p {
  left: 2.5rem;
}
#f .f-input-group {
  background-color: var(--fondy-input_bg);
  border-radius: 0.5rem;
}
#f .f-input-group .f-form-group {
  margin: 0;
}
#f .f-input-group .f-form-group:after {
  position: absolute;
  top: 0.5rem;
  right: 0;
  bottom: 0.5rem;
  width: 0.0625rem;
  content: '';
  background-color: var(--fondy-input_border);
}
#f .f-input-group .f-form-group.f-has-error:after,
#f .f-input-group .f-form-group:last-child:after {
  display: none;
}
#f .f-btn {
  position: relative;
  display: inline-block;
  padding: 0.625rem 2rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: center;
  background: none;
  border: none;
  border-radius: 0.5rem;
  transition: all 0.15s ease-in-out;
}
#f .f-btn span {
  position: relative;
  z-index: 1;
}
#f .f-btn:before {
  bottom: 0.125rem;
  left: 5%;
  width: 90%;
  height: 2rem;
  border-radius: 1rem;
}
#f .f-btn:after,
#f .f-btn:before {
  position: absolute;
  z-index: 0;
  content: '';
  transition: all 0.15s ease-in-out;
}
#f .f-btn:after {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
}
#f .f-btn:focus,
#f .f-btn:hover {
  text-decoration: none;
  -webkit-transform: translateY(-0.125rem);
  transform: translateY(-0.125rem);
}
#f .f-btn:active {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
#f .f-btn:focus {
  outline: 0;
}
#f .f-btn :not(:disabled):not(.disabled) {
  cursor: pointer;
}
#f .f-btn-success {
  color: var(--fondy-btn_success_color);
}
#f .f-btn-success:before {
  box-shadow: 0 0.625rem 1.5625rem
    hsla(
      var(--fondy-btn_success_shadow-h),
      var(--fondy-btn_success_shadow-s),
      var(--fondy-btn_success_shadow-l),
      0.6
    );
}
#f .f-btn-success:after {
  background: var(--fondy-btn_success_bg);
}
#f .f-btn-success:focus,
#f .f-btn-success:hover {
  color: var(--fondy-btn_success_color);
}
#f .f-btn-success:focus:before,
#f .f-btn-success:hover:before {
  box-shadow: 0 0.625rem 1.5625rem
    hsla(
      var(--fondy-btn_success_shadow-h),
      var(--fondy-btn_success_shadow-s),
      var(--fondy-btn_success_shadow-l),
      0.6
    );
}
#f .f-btn-success:focus:after,
#f .f-btn-success:hover:after {
  background: hsl(
    var(--fondy-btn_success_bg-h),
    var(--fondy-btn_success_bg-s),
    calc(var(--fondy-btn_success_bg-l) - 10%)
  );
}
#f .f-btn-success:active {
  color: var(--fondy-btn_success_color);
}
#f .f-btn-success:active:before {
  box-shadow: 0 0.625rem 1.5625rem
    hsla(
      var(--fondy-btn_success_shadow-h),
      var(--fondy-btn_success_shadow-s),
      var(--fondy-btn_success_shadow-l),
      0.6
    );
}
#f .f-btn-success:active:after {
  background: hsl(
    var(--fondy-btn_success_bg-h),
    var(--fondy-btn_success_bg-s),
    calc(var(--fondy-btn_success_bg-l) - 20%)
  );
}
#f .f-btn-success.disabled,
#f .f-btn-success:disabled {
  color: var(--fondy-btn_disabled_color);
}
#f .f-btn-success.disabled:before,
#f .f-btn-success:disabled:before {
  box-shadow: none;
}
#f .f-btn-success.disabled:after,
#f .f-btn-success:disabled:after {
  background: var(--fondy-btn_disabled_bg);
}
#f .f-theme-dark .f-btn-success:before {
  box-shadow: 0 0.625rem 1.5625rem rgba(0, 0, 0, 0.6);
}
#f .f-btn-success-bg-lighten:focus:after,
#f .f-btn-success-bg-lighten:hover:after {
  background: hsl(
    var(--fondy-btn_success_bg-h),
    var(--fondy-btn_success_bg-s),
    calc(var(--fondy-btn_success_bg-l) + 10%)
  );
}
#f .f-btn-success-bg-lighten:active:after {
  background: hsl(
    var(--fondy-btn_success_bg-h),
    var(--fondy-btn_success_bg-s),
    calc(var(--fondy-btn_success_bg-l) + 20%)
  );
}
#f .f-btn-success-bg-lighten.disabled,
#f .f-btn-success-bg-lighten:disabled {
  color: var(--fondy-btn_disabled_color);
}
#f .f-btn-success-bg-lighten.disabled:before,
#f .f-btn-success-bg-lighten:disabled:before {
  box-shadow: none;
}
#f .f-btn-success-bg-lighten.disabled:after,
#f .f-btn-success-bg-lighten:disabled:after {
  background: var(--fondy-btn_disabled_bg);
}
#f .f-btn-success-gradient:active:after,
#f .f-btn-success-gradient:after,
#f .f-btn-success-gradient:focus:after,
#f .f-btn-success-gradient:hover:after {
  background: linear-gradient(
    130deg,
    hsl(
        var(--fondy-btn_success_bg-h),
        calc(var(--fondy-btn_success_bg-s) + 15%),
        calc(var(--fondy-btn_success_bg-l) - 5%)
      )
      11%,
    hsl(
        var(--fondy-btn_success_bg-h),
        calc(var(--fondy-btn_success_bg-s) + 10%),
        calc(var(--fondy-btn_success_bg-l) - 15%)
      )
      22%,
    hsl(
        var(--fondy-btn_success_bg-h),
        calc(var(--fondy-btn_success_bg-s) + 10%),
        calc(var(--fondy-btn_success_bg-l) - 15%)
      )
      32%,
    hsl(
        var(--fondy-btn_success_bg-h),
        calc(var(--fondy-btn_success_bg-s) + 15%),
        calc(var(--fondy-btn_success_bg-l) - 15%)
      )
      33%,
    hsl(
        var(--fondy-btn_success_bg-h),
        calc(var(--fondy-btn_success_bg-s) + 10%),
        calc(var(--fondy-btn_success_bg-l) - 25%)
      )
      55%,
    hsl(
        var(--fondy-btn_success_bg-h),
        calc(var(--fondy-btn_success_bg-s) + 10%),
        calc(var(--fondy-btn_success_bg-l) - 25%)
      )
      65%,
    hsl(
        var(--fondy-btn_success_bg-h),
        calc(var(--fondy-btn_success_bg-s) + 15%),
        calc(var(--fondy-btn_success_bg-l) - 25%)
      )
      66%,
    hsl(
        var(--fondy-btn_success_bg-h),
        calc(var(--fondy-btn_success_bg-s) + 10%),
        calc(var(--fondy-btn_success_bg-l) - 35%)
      )
      88%
  );
  background-position: 0 0;
  background-size: 300% 300%;
}
#f .f-btn-success-gradient:focus:after,
#f .f-btn-success-gradient:hover:after {
  background-position: 50% 50%;
}
#f .f-btn-success-gradient:active:after {
  background-position: 100% 100%;
}
#f .f-btn-success-gradient.disabled,
#f .f-btn-success-gradient:disabled {
  color: var(--fondy-btn_disabled_color);
}
#f .f-btn-success-gradient.disabled:before,
#f .f-btn-success-gradient:disabled:before {
  box-shadow: none;
}
#f .f-btn-success-gradient.disabled:after,
#f .f-btn-success-gradient:disabled:after {
  background: var(--fondy-btn_disabled_bg);
}
#f .f-btn-success-gradient-custom:active:after,
#f .f-btn-success-gradient-custom:after,
#f .f-btn-success-gradient-custom:focus:after,
#f .f-btn-success-gradient-custom:hover:after {
  background: var(--fondy-btn_success_gradient_custom);
  background-repeat: no-repeat;
}
#f .f-btn-success-gradient-custom:focus:after,
#f .f-btn-success-gradient-custom:hover:after {
  background-position-x: -25px;
}
#f .f-btn-success-gradient-custom:active:after {
  background-position-x: -50px;
}
#f .f-btn-success-gradient-custom.disabled,
#f .f-btn-success-gradient-custom:disabled {
  color: var(--fondy-btn_disabled_color);
}
#f .f-btn-success-gradient-custom.disabled:before,
#f .f-btn-success-gradient-custom:disabled:before {
  box-shadow: none;
}
#f .f-btn-success-gradient-custom.disabled:after,
#f .f-btn-success-gradient-custom:disabled:after {
  background: var(--fondy-btn_disabled_bg);
}
#f .f-btn-default {
  font-size: 1rem;
  font-weight: 500;
  color: var(--fondy-btn_default_color);
}
#f .f-btn-default:after {
  background: var(--fondy-btn_default_bg);
}
#f .f-btn-default:focus,
#f .f-btn-default:hover {
  color: var(--fondy-btn_default_hover_color);
}
#f .f-btn-default:focus:before,
#f .f-btn-default:hover:before {
  box-shadow: 0 0.625rem 1.5625rem
    hsla(
      var(--fondy-btn_default_hover_shadow-h),
      var(--fondy-btn_default_hover_shadow-s),
      var(--fondy-btn_default_hover_shadow-l),
      0.6
    );
}
#f .f-btn-default:focus:after,
#f .f-btn-default:hover:after {
  background: var(--fondy-btn_default_hover_bg);
}
#f .f-btn-default:active {
  color: var(--fondy-btn_default_active_color);
}
#f .f-btn-default:active:before {
  box-shadow: 0 0.625rem 1.5625rem
    hsla(
      var(--fondy-btn_default_active_shadow-h),
      var(--fondy-btn_default_active_shadow-s),
      var(--fondy-btn_default_active_shadow-l),
      0.6
    );
}
#f .f-btn-default:active:after {
  background: var(--fondy-btn_default_active_bg);
}
#f .f-btn-default.disabled,
#f .f-btn-default:disabled {
  color: var(--fondy-btn_disabled_color);
}
#f .f-btn-default.disabled:before,
#f .f-btn-default:disabled:before {
  box-shadow: none;
}
#f .f-btn-default.disabled:after,
#f .f-btn-default:disabled:after {
  background: var(--fondy-btn_disabled_bg);
}
#f .f-btn-secondary {
  font-size: 1rem;
  font-weight: 500;
  color: var(--fondy-btn_secondary_color);
}
#f .f-btn-secondary:after {
  background: var(--fondy-btn_secondary_bg);
}
#f .f-btn-secondary:focus,
#f .f-btn-secondary:hover {
  color: var(--fondy-btn_secondary_hover_color);
}
#f .f-btn-secondary:focus:before,
#f .f-btn-secondary:hover:before {
  box-shadow: 0 0.625rem 1.5625rem
    hsla(
      var(--fondy-btn_secondary_hover_shadow-h),
      var(--fondy-btn_secondary_hover_shadow-s),
      var(--fondy-btn_secondary_hover_shadow-l),
      0.6
    );
}
#f .f-btn-secondary:focus:after,
#f .f-btn-secondary:hover:after {
  background: var(--fondy-btn_secondary_hover_bg);
}
#f .f-btn-secondary:active {
  color: var(--fondy-btn_secondary_active_color);
}
#f .f-btn-secondary:active:before {
  box-shadow: 0 0.625rem 1.5625rem
    hsla(
      var(--fondy-btn_secondary_active_shadow-h),
      var(--fondy-btn_secondary_active_shadow-s),
      var(--fondy-btn_secondary_active_shadow-l),
      0.6
    );
}
#f .f-btn-secondary:active:after {
  background: var(--fondy-btn_secondary_active_bg);
}
#f .f-btn-secondary.disabled,
#f .f-btn-secondary:disabled {
  color: var(--fondy-btn_disabled_color);
}
#f .f-btn-secondary.disabled:before,
#f .f-btn-secondary:disabled:before {
  box-shadow: none;
}
#f .f-btn-secondary.disabled:after,
#f .f-btn-secondary:disabled:after {
  background: var(--fondy-btn_disabled_bg);
}
#f .f-btn-outline {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--fondy-btn_outline_color);
}
#f .f-btn-outline:after {
  background: var(--fondy-btn_outline_bg);
  border: 1px solid var(--fondy-btn_outline_border);
}
#f .f-btn-outline:focus,
#f .f-btn-outline:hover {
  color: var(--fondy-btn_outline_hover_color);
}
#f .f-btn-outline:focus:after,
#f .f-btn-outline:hover:after {
  background: var(--fondy-btn_outline_hover_bg);
  border: 1px solid var(--fondy-btn_outline_hover_border);
}
#f .f-btn-outline:active {
  color: var(--fondy-btn_outline_active_color);
}
#f .f-btn-outline:active:after {
  background: var(--fondy-btn_outline_active_bg);
  border: 1px solid var(--fondy-btn_outline_active_border);
}
#f .f-btn-outline.disabled,
#f .f-btn-outline:disabled {
  color: var(--fondy-btn_disabled_color);
}
#f .f-btn-outline.disabled:before,
#f .f-btn-outline:disabled:before {
  box-shadow: none;
}
#f .f-btn-outline.disabled:after,
#f .f-btn-outline:disabled:after {
  background: var(--fondy-btn_disabled_bg);
}
#f .f-btn-block {
  display: block;
  width: 100%;
}
#f .f-btn-lg {
  padding: 1rem 2rem;
}
#f .f-btn-link {
  text-align: left;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
#f .f-btn-link-default {
  color: var(--fondy-btn_link_default_color);
}
#f .f-btn-link-default:focus,
#f .f-btn-link-default:hover {
  color: var(--fondy-btn_link_default_hover_color);
}
#f .f-btn-link-default:active {
  color: var(--fondy-btn_link_default_active_color);
}
#f .f-btn-link-secondary {
  color: var(--fondy-btn_link_secondary_color);
}
#f .f-btn-link-secondary:focus,
#f .f-btn-link-secondary:hover {
  color: var(--fondy-btn_link_secondary_hover_color);
}
#f .f-btn-link-secondary:active {
  color: var(--fondy-btn_link_secondary_active_color);
}
html .modal-open {
  overflow: hidden;
}
html .modal-open .f-modal {
  overflow-x: hidden;
  overflow-y: auto;
}
#f .f-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
#f .f-modal.fade .f-modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate3d(0, 25%, 0);
  transform: translate3d(0, 25%, 0);
}
#f .f-modal.show .f-modal-dialog {
  -webkit-transform: none;
  transform: none;
}
#f .f-modal .f-modal.modal-static .f-modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}
#f .f-modal-dialog {
  position: relative;
  width: auto;
  margin: 0;
  pointer-events: none;
}
#f .f-modal-dialog-scrollable {
  max-height: calc(100% - 4rem);
}
#f .f-modal-dialog-scrollable .f-modal-content {
  overflow: hidden;
}
#f .f-modal-dialog-scrollable .f-modal-body {
  overflow-y: auto;
}
#f .f-modal-dialog-centered {
  display: flex;
  align-items: flex-end;
  height: 100%;
  min-height: 100%;
}
#f .f-modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.25rem 0 0;
  margin-top: 4rem;
  font-size: 0.875rem;
  color: var(--fondy-modal_content_color);
  pointer-events: auto;
  background-color: var(--fondy-modal_content_bg);
  background-clip: padding-box;
  border-radius: 0.75rem 0.75rem 0 0;
  outline: 0;
  box-shadow: 0 20px 30px rgba(57, 64, 75, 0.3);
}
#f .f-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: var(--fondy-modal_backdrop_bg);
  opacity: 0.7;
}
#f .f-modal-backdrop.fade {
  opacity: 0;
}
#f .f-modal-backdrop.show {
  opacity: 0.7;
}
#f .f-modal-header {
  position: relative;
  padding: 0 1.25rem 1rem;
}
#f .f-modal-header .f-close {
  position: absolute;
  top: -5rem;
  right: 0.25rem;
  padding: 1rem;
  font-size: 2rem;
}
#f .f-modal-title {
  margin: 0;
  margin-bottom: 1.25rem;
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 0;
  color: var(--fondy-modal_title_color);
}
#f .f-modal-title svg {
  margin: -1rem 0;
}
#f .f-modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0 1.25rem 1.25rem;
  line-height: 1.5;
}
#f .f-modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 0 1.25rem 1.25rem;
}
#f .modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (max-width: 374.98px) {
  #f .modal-fullscreen-ss-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  #f .modal-fullscreen-ss-down .f-modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  #f .modal-fullscreen-ss-down .f-modal-header {
    border-radius: 0;
  }
  #f .modal-fullscreen-ss-down .f-modal-body {
    overflow-y: auto;
  }
  #f .modal-fullscreen-ss-down .f-modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 575.98px) {
  #f .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  #f .modal-fullscreen-sm-down .f-modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  #f .modal-fullscreen-sm-down .f-modal-header {
    border-radius: 0;
  }
  #f .modal-fullscreen-sm-down .f-modal-body {
    overflow-y: auto;
  }
  #f .modal-fullscreen-sm-down .f-modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  #f .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  #f .modal-fullscreen-md-down .f-modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  #f .modal-fullscreen-md-down .f-modal-header {
    border-radius: 0;
  }
  #f .modal-fullscreen-md-down .f-modal-body {
    overflow-y: auto;
  }
  #f .modal-fullscreen-md-down .f-modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  #f .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  #f .modal-fullscreen-lg-down .f-modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  #f .modal-fullscreen-lg-down .f-modal-header {
    border-radius: 0;
  }
  #f .modal-fullscreen-lg-down .f-modal-body {
    overflow-y: auto;
  }
  #f .modal-fullscreen-lg-down .f-modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  #f .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  #f .modal-fullscreen-xl-down .f-modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  #f .modal-fullscreen-xl-down .f-modal-header {
    border-radius: 0;
  }
  #f .modal-fullscreen-xl-down .f-modal-body {
    overflow-y: auto;
  }
  #f .modal-fullscreen-xl-down .f-modal-footer {
    border-radius: 0;
  }
}
#f .modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
#f .modal-fullscreen .f-modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
#f .modal-fullscreen .f-modal-header {
  border-radius: 0;
}
#f .modal-fullscreen .f-modal-body {
  overflow-y: auto;
}
#f .modal-fullscreen .f-modal-footer {
  border-radius: 0;
}
#f .f-close {
  padding: 0;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1;
  color: var(--fondy-close_color);
  cursor: pointer;
  background-color: transparent;
  border: 0;
  opacity: 1;
}
#f .f-close:hover {
  text-decoration: none;
}
#f .f-close:focus,
#f .f-close:hover {
  outline: 0;
  opacity: 0.75;
}
#f .f-close.disabled,
#f .f-close:disabled {
  pointer-events: none;
}
#f .f-checkbox {
  position: absolute;
  z-index: 1;
  width: 1.25rem;
  opacity: 0;
}
#f .f-checkbox:checked + .f-checkbox-label:before {
  border: none;
}
#f .f-checkbox:checked + .f-checkbox-label:after {
  opacity: 1;
}
#f .f-checkbox-label {
  position: relative;
  padding: 0 0 0 1.875rem;
  font-size: 1rem;
  cursor: pointer;
}
#f .f-checkbox-label:before {
  position: absolute;
  top: 0.0625rem;
  left: 0;
  width: 1.25rem;
  height: 1.25rem;
  content: '';
  border-style: solid;
  border-width: 0.09375rem;
  border-radius: 0.25rem;
  transition: background-color 0.15s ease-in-out;
}
#f .f-checkbox-label:after {
  position: absolute;
  top: 0.375rem;
  left: 0.3125rem;
  width: 0.625rem;
  height: 0.4375rem;
  content: '';
  background: transparent;
  border-style: solid;
  border-width: 0.125rem;
  border-top: none;
  border-right: none;
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
#f .f-checkbox-label:hover:after {
  opacity: 1;
}
#f .f-form-item-sm > .f-checkbox-label {
  font-size: 0.75rem;
  line-height: 1.375rem;
}
#f .f-checkbox-default + .f-checkbox-label {
  color: var(--fondy-label_color);
}
#f .f-checkbox-default + .f-checkbox-label:before {
  background-color: var(--fondy-input_bg);
  border-color: var(--fondy-checkbox_default_border);
}
#f .f-has-error .f-checkbox-default + .f-checkbox-label:before {
  border-color: var(--fondy-input_error_border);
}
#f .f-checkbox-default + .f-checkbox-label:hover:before {
  background-color: var(--fondy-checkbox_default_hover_bg);
  border-color: var(--fondy-checkbox_default_hover_border);
}
#f .f-checkbox-default + .f-checkbox-label:hover:after {
  border-color: var(--fondy-checkbox_default_hover_color);
}
#f .f-checkbox-default:checked + .f-checkbox-label:before {
  background-color: var(--fondy-checkbox_default_checked_bg);
}
#f .f-checkbox-default:checked + .f-checkbox-label:after {
  border-color: var(--fondy-checkbox_default_checked_color);
}
#f .f-checkbox-default[disabled] + .f-checkbox-label {
  cursor: default;
}
#f .f-checkbox-default[disabled] + .f-checkbox-label:before {
  background-color: var(--fondy-input_bg);
  border-color: var(--fondy-checkbox_default_border);
}
#f .f-checkbox-default[disabled] + .f-checkbox-label:after {
  opacity: 0;
}
#f .f-checkbox-secondary + .f-checkbox-label {
  color: var(--fondy-checkbox_secondary_label_color);
}
#f .f-checkbox-secondary + .f-checkbox-label:before {
  background-color: var(--fondy-input_bg);
  border-color: var(--fondy-checkbox_secondary_border);
}
#f .f-has-error .f-checkbox-secondary + .f-checkbox-label:before {
  border-color: var(--fondy-input_error_border);
}
#f .f-checkbox-secondary + .f-checkbox-label:hover:before {
  background-color: var(--fondy-checkbox_secondary_hover_bg);
  border-color: var(--fondy-checkbox_secondary_hover_border);
}
#f .f-checkbox-secondary + .f-checkbox-label:hover:after {
  border-color: var(--fondy-checkbox_secondary_hover_color);
}
#f .f-checkbox-secondary:checked + .f-checkbox-label:before {
  background-color: var(--fondy-checkbox_default_checked_color);
}
#f .f-checkbox-secondary:checked + .f-checkbox-label:after {
  border-color: var(--fondy-checkbox_secondary_checked_color);
}
#f .f-checkbox-secondary[disabled] + .f-checkbox-label {
  cursor: default;
}
#f .f-checkbox-secondary[disabled] + .f-checkbox-label:before {
  background-color: var(--fondy-input_bg);
  border-color: var(--fondy-checkbox_secondary_border);
}
#f .f-checkbox-secondary[disabled] + .f-checkbox-label:after {
  opacity: 0;
}
#f .f-input-group,
#f .f-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}
#f .f-input-group,
#f .f-no-gutters {
  margin-right: 0;
  margin-left: 0;
}
#f .f-input-group > .f-col,
#f .f-input-group > [class*='f-col-'],
#f .f-no-gutters > .f-col,
#f .f-no-gutters > [class*='f-col-'] {
  padding-right: 0;
  padding-left: 0;
}
#f .f-col {
  min-width: 0;
}
#f .f-col,
#f .f-col-1,
#f .f-col-2,
#f .f-col-3,
#f .f-col-4,
#f .f-col-5,
#f .f-col-6,
#f .f-col-7,
#f .f-col-8,
#f .f-col-9,
#f .f-col-10,
#f .f-col-11,
#f .f-col-12,
#f .f-col-auto,
#f .f-col-lg,
#f .f-col-lg-1,
#f .f-col-lg-2,
#f .f-col-lg-3,
#f .f-col-lg-4,
#f .f-col-lg-5,
#f .f-col-lg-6,
#f .f-col-lg-7,
#f .f-col-lg-8,
#f .f-col-lg-9,
#f .f-col-lg-10,
#f .f-col-lg-11,
#f .f-col-lg-12,
#f .f-col-lg-auto,
#f .f-col-md,
#f .f-col-md-1,
#f .f-col-md-2,
#f .f-col-md-3,
#f .f-col-md-4,
#f .f-col-md-5,
#f .f-col-md-6,
#f .f-col-md-7,
#f .f-col-md-8,
#f .f-col-md-9,
#f .f-col-md-10,
#f .f-col-md-11,
#f .f-col-md-12,
#f .f-col-md-auto,
#f .f-col-sm,
#f .f-col-sm-1,
#f .f-col-sm-2,
#f .f-col-sm-3,
#f .f-col-sm-4,
#f .f-col-sm-5,
#f .f-col-sm-6,
#f .f-col-sm-7,
#f .f-col-sm-8,
#f .f-col-sm-9,
#f .f-col-sm-10,
#f .f-col-sm-11,
#f .f-col-sm-12,
#f .f-col-sm-auto,
#f .f-col-ss,
#f .f-col-ss-1,
#f .f-col-ss-2,
#f .f-col-ss-3,
#f .f-col-ss-4,
#f .f-col-ss-5,
#f .f-col-ss-6,
#f .f-col-ss-7,
#f .f-col-ss-8,
#f .f-col-ss-9,
#f .f-col-ss-10,
#f .f-col-ss-11,
#f .f-col-ss-12,
#f .f-col-ss-auto,
#f .f-col-xl,
#f .f-col-xl-1,
#f .f-col-xl-2,
#f .f-col-xl-3,
#f .f-col-xl-4,
#f .f-col-xl-5,
#f .f-col-xl-6,
#f .f-col-xl-7,
#f .f-col-xl-8,
#f .f-col-xl-9,
#f .f-col-xl-10,
#f .f-col-xl-11,
#f .f-col-xl-12,
#f .f-col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
#f .f-col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
#f .f-col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
#f .f-col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}
#f .f-col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}
#f .f-col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
#f .f-col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}
#f .f-col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}
#f .f-col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
#f .f-col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}
#f .f-col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}
#f .f-col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
#f .f-col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}
#f .f-col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}
#f .f-col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
@media (min-width: 375px) {
  #f .f-col-ss {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  #f .f-col-ss-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  #f .f-col-ss-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  #f .f-col-ss-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  #f .f-col-ss-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  #f .f-col-ss-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  #f .f-col-ss-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  #f .f-col-ss-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  #f .f-col-ss-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  #f .f-col-ss-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  #f .f-col-ss-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  #f .f-col-ss-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  #f .f-col-ss-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  #f .f-col-ss-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 576px) {
  #f .f-col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  #f .f-col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  #f .f-col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  #f .f-col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  #f .f-col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  #f .f-col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  #f .f-col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  #f .f-col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  #f .f-col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  #f .f-col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  #f .f-col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  #f .f-col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  #f .f-col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  #f .f-col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  #f .f-col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  #f .f-col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  #f .f-col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  #f .f-col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  #f .f-col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  #f .f-col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  #f .f-col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  #f .f-col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  #f .f-col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  #f .f-col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  #f .f-col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  #f .f-col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  #f .f-col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  #f .f-col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  #f .f-col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  #f .f-col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  #f .f-col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  #f .f-col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  #f .f-col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  #f .f-col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  #f .f-col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  #f .f-col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  #f .f-col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  #f .f-col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  #f .f-col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  #f .f-col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  #f .f-col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  #f .f-col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  #f .f-col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  #f .f-col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  #f .f-col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  #f .f-col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  #f .f-col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  #f .f-col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  #f .f-col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  #f .f-col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  #f .f-col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  #f .f-col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  #f .f-col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  #f .f-col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  #f .f-col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  #f .f-col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
#f .f-switch {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.2rem;
  opacity: 0;
}
#f .f-switch:checked + .f-switch-label:before {
  background-color: var(--fondy-switch_checked_bg);
}
#f .f-switch:checked + .f-switch-label:after {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  -webkit-transform: translateX(1.125rem);
  transform: translateX(1.125rem);
}
#f .f-switch-label {
  position: relative;
  display: block;
  cursor: pointer;
}
#f .f-switch-label:before {
  position: absolute;
  top: 0.0125rem;
  right: 0;
  display: block;
  width: 2.5rem;
  height: 1.375rem;
  pointer-events: all;
  content: '';
  background-color: var(--fondy-switch_bg);
  border-radius: 0.9375rem;
  transition: background-color 0.15s ease-in-out;
}
#f .f-switch-label:after {
  position: absolute;
  top: 0.2rem;
  right: 1.3125rem;
  width: 1rem;
  height: 1rem;
  content: '';
  background-color: var(--fondy-switch_indicator_bg);
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  transition: box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out;
}
#f .f-switch-label:hover:before {
  background-color: var(--fondy-switch_hover_bg);
}
#f .f-switch-label:hover:after {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
}
#f .f-svg {
  display: inline-block;
  min-width: 1em;
  height: 1em;
  overflow: visible;
  font-size: inherit;
  vertical-align: -0.125em;
}
#f .f-svg-w-2 {
  width: 0.125em;
}
#f .f-svg-w-3 {
  width: 0.1875em;
}
#f .f-svg-w-4 {
  width: 0.25em;
}
#f .f-svg-w-5 {
  width: 0.3125em;
}
#f .f-svg-w-6 {
  width: 0.375em;
}
#f .f-svg-w-7 {
  width: 0.4375em;
}
#f .f-svg-w-8 {
  width: 0.5em;
}
#f .f-svg-w-9 {
  width: 0.5625em;
}
#f .f-svg-w-10 {
  width: 0.625em;
}
#f .f-svg-w-11 {
  width: 0.6875em;
}
#f .f-svg-w-12 {
  width: 0.75em;
}
#f .f-svg-w-13 {
  width: 0.8125em;
}
#f .f-svg-w-14 {
  width: 0.875em;
}
#f .f-svg-w-15 {
  width: 0.9375em;
}
#f .f-svg-w-16 {
  width: 1em;
}
#f .f-svg-w-17 {
  width: 1.0625em;
}
#f .f-svg-w-18 {
  width: 1.125em;
}
#f .f-svg-w-19 {
  width: 1.1875em;
}
#f .f-svg-w-20 {
  width: 1.25em;
}
#f .f-svg-lg {
  font-size: 1.5em;
  line-height: 0.75em;
  vertical-align: -35%;
}
#f .f-svg-xs {
  font-size: 0.75em;
}
#f .f-svg-sm {
  font-size: 0.875em;
}
#f .f-svg-1x {
  font-size: 1em;
}
#f .f-svg-2x {
  font-size: 2em;
}
#f .f-svg-3x {
  font-size: 3em;
}
#f .f-svg-4x {
  font-size: 4em;
}
#f .f-svg-5x {
  font-size: 5em;
}
#f .f-svg-6x {
  font-size: 6em;
}
#f .f-svg-7x {
  font-size: 7em;
}
#f .f-svg-8x {
  font-size: 8em;
}
#f .f-svg-9x {
  font-size: 9em;
}
#f .f-svg-10x {
  font-size: 10em;
}
#f .f-svg-spin {
  -webkit-animation: f-svg-spin 2s linear infinite;
  animation: f-svg-spin 2s linear infinite;
}
#f .f-svg-fw {
  width: 2.5rem;
  min-width: 2.5rem;
}
@-webkit-keyframes f-svg-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes f-svg-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
#f .f-icon {
  display: inline-block;
  vertical-align: middle;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 90%;
}
#f .f-icon-block {
  display: block;
  width: 100%;
}
#f .f-icon-block,
#f .f-icon-contain,
#f .f-icon-sm {
  background-size: contain;
}
#f .f-icon-sm {
  width: 1.5rem;
  height: 1.5rem;
}
#f .f-icon-md {
  width: 3rem;
  height: 3rem;
  background-size: contain;
}
#f .f-icon-lg {
  width: 4rem;
  height: 4rem;
  background-size: contain;
}
#f .f-icon-no_logo {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjU2IiBoZWlnaHQ9IjI1NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMCAwaDI1NnYyNTZIMFYweiIgZmlsbD0iI0VFRjBGMiIvPjxyZWN0IHg9IjYxLjMzNCIgeT0iMTY4IiB3aWR0aD0iMTMzLjMzMyIgaGVpZ2h0PSIyMCIgcng9IjYuNjY3IiBmaWxsPSIjQzhDRUQ1Ii8+PHBhdGggZD0iTTYxLjMzNCA5Mi41MTJhNi42NjcgNi42NjcgMCAwMTQuMTktNi4xOWw1Ny41MjUtMjMuMDFhMTMuMzM0IDEzLjMzNCAwIDAxOS45MDQgMGw1Ny41MjQgMjMuMDFhNi42NjYgNi42NjYgMCAwMTQuMTkgNi4xOXY4LjgyYTYuNjY2IDYuNjY2IDAgMDEtNi42NjYgNi42NjdoLTEyMGE2LjY2NyA2LjY2NyAwIDAxLTYuNjY3LTYuNjY3di04Ljgyek03OCAxNjh2LTYwaDIwdjYwem04MCAwdi02MGgyMHY2MHptLTQwIDB2LTYwaDIwdjYweiIgZmlsbD0iI0M4Q0VENSIvPjwvc3ZnPg==);
}
#f .f-alert {
  position: relative;
  padding: 1.5rem 1.25rem;
  margin-bottom: 1rem;
  border-radius: 1rem;
}
#f .f-alert-dismissible {
  padding-right: 4.375rem;
}
#f .f-alert-dismissible .f-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.375rem 1.25rem;
  color: inherit;
}
#f .f-alert-info {
  background-color: hsla(
    var(--fondy-alert_info_bg-h),
    var(--fondy-alert_info_bg-s),
    var(--fondy-alert_info_bg-l),
    0.9
  );
}
#f .f-alert-info,
#f .f-alert-info a {
  color: var(--fondy-alert_info_color);
}
#f .f-datepicker-datepicker,
#f .f-datepicker-input-wrapper {
  position: relative;
}
#f .f-datepicker-icon-calendar {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
#f .f-datepicker-datepicker-main {
  background-color: var(--fondy-datepicker_bg);
}
#f .f-datepicker-datepicker-popup {
  position: absolute;
  z-index: 1000;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 2rem
    hsla(
      var(--fondy-datepicker_shadow-h),
      var(--fondy-datepicker_shadow-s),
      var(--fondy-datepicker_shadow-l),
      0.5
    );
}
#f .f-datepicker-datepicker-body {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#f .f-datepicker-calendar {
  width: 336px;
  padding: 1.375rem 1.125rem 0.8125rem;
}
#f .f-datepicker-calendar-header {
  display: flex;
  padding: 0 0.5rem;
}
#f .f-datepicker-calendar-header-label {
  margin-right: auto;
}
#f .f-datepicker-btn-icon-double-left,
#f .f-datepicker-btn-icon-double-right {
  display: none;
}
#f .f-datepicker-btn-icon-left {
  order: 1;
}
#f .f-datepicker-btn-icon-right {
  order: 2;
}
#f .f-datepicker-calendar-decade-separator {
  margin: 0 0.125rem;
}
#f .f-datepicker-calendar-decade-separator:after {
  content: '~';
}
#f .f-datepicker-calendar-content {
  position: relative;
  height: 17rem;
}
#f .f-datepicker-table {
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  table-layout: fixed;
  border-spacing: 0.25rem 0;
  border-collapse: separate;
}
#f .f-datepicker-table th {
  padding: 0;
  font-weight: 500;
}
#f .f-datepicker-table td {
  padding: 0;
}
#f .f-datepicker-table .not-current-month {
  color: var(--fondy-datepicker_cell_not_current_month_color);
}
#f .f-datepicker-table .today {
  color: var(--fondy-datepicker_cell_today_color);
  background-color: var(--fondy-datepicker_cell_today_bg);
}
#f .f-datepicker-table .cell {
  height: 2.5rem;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.15s ease-in-out;
}
#f .f-datepicker-table .cell:hover {
  color: var(--fondy-datepicker_cell_hover_color);
  background-color: var(--fondy-datepicker_cell_hover_bg);
}
#f .f-datepicker-table .cell.active,
#f .f-datepicker-table .cell:active {
  color: var(--fondy-datepicker_cell_active_color);
  background-color: var(--fondy-datepicker_cell_active_bg);
}
#f .f-datepicker-table .cell.disabled {
  color: var(--fondy-datepicker_cell_disabled_color);
  cursor: not-allowed;
}
#f .f-datepicker-table-date th {
  height: 2rem;
  font-size: 0.625rem;
  color: var(--fondy-datepicker_th_color);
  text-transform: uppercase;
}
#f .f-datepicker-table-month .cell,
#f .f-datepicker-table-year .cell {
  border-radius: 0;
}
#f .f-datepicker-icon-double-left:after,
#f .f-datepicker-icon-double-left:before,
#f .f-datepicker-icon-double-right:after,
#f .f-datepicker-icon-double-right:before,
#f .f-datepicker-icon-left:before,
#f .f-datepicker-icon-right:before {
  position: relative;
  top: -0.0625rem;
  display: inline-block;
  width: 0.625rem;
  height: 0.625rem;
  vertical-align: middle;
  content: '';
  border-color: currentColor;
  border-style: solid;
  border-width: 0.125rem 0 0 0.125rem;
  border-radius: 0.0625rem;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: center;
  transform-origin: center;
}
#f .f-datepicker-icon-double-left:after {
  left: -0.25rem;
}
#f .f-datepicker-icon-double-right:before {
  left: 0.25rem;
}
#f .f-datepicker-icon-double-right:after,
#f .f-datepicker-icon-double-right:before,
#f .f-datepicker-icon-right:before {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
#f .f-datepicker-btn-text {
  padding: 0 0.25rem;
  margin: 0;
  font-size: 1.125rem;
  font-weight: 500;
  color: var(--fondy-datepicker_btn_text_color);
  text-align: left;
  white-space: nowrap;
  cursor: pointer;
  background-color: transparent;
  border: 0;
}
#f .f-datepicker-btn-text:focus {
  outline: 0;
}
#f .f-scrollbar {
  height: 100%;
}
#f .f-scrollbar:hover .f-scrollbar-track {
  opacity: 1;
}
#f .f-scrollbar-wrap {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}
#f .f-scrollbar-track {
  position: absolute;
  top: 0.125rem;
  right: 0.125rem;
  bottom: 0.125rem;
  z-index: 1;
  width: 0.25rem;
  border-radius: 0.25rem;
  opacity: 0;
  transition: opacity 0.25s ease-out;
}
#f .f-scrollbar-thumb {
  position: absolute;
  width: 100%;
  height: 0;
  cursor: pointer;
  background-color: hsla(
    var(--fondy-scrollbar_thumb_bg-h),
    var(--fondy-scrollbar_thumb_bg-s),
    var(--fondy-scrollbar_thumb_bg-l),
    0.3
  );
  border-radius: inherit;
}
#f .f-loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
#f .f-loading-wrapper {
  z-index: 980;
}
#f .f-loading-backdrop,
#f .f-loading-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
#f .f-loading-backdrop {
  background-color: var(--fondy-container_bg);
  opacity: 0.7;
}
#f .f-loading-loader {
  width: 4rem;
  height: 4rem;
  border: 0.1875rem solid var(--fondy-input_border);
  border-top: 0.1875rem solid var(--fondy-main);
  border-radius: 50%;
  -webkit-animation: f-loading 2s linear infinite;
  animation: f-loading 2s linear infinite;
}
@-webkit-keyframes f-loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes f-loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
#f {
  font-family: inter, sans-serif;
  font-size: 1rem;
  line-height: 1.4;
  color: var(--fondy-main_color);
  background-color: var(--fondy-main_bg);
}
#f .f-container {
  position: relative;
  width: 100%;
  height: 100%;
  background: var(--fondy-container_bg);
}
#f .f-demo {
  position: absolute;
  right: 0;
  left: 0;
  display: block;
  height: 1rem;
  content: '';
  border-top: 2px solid #30d769;
}
#f .f-demo-desc {
  font-size: 0.875rem;
  text-align: center;
}
#f .f-header {
  display: flex;
  background: var(--fondy-container_bg);
}
#f .f-header-logo {
  display: flex;
  flex: 0 0 50%;
  flex-wrap: wrap;
  align-items: center;
  max-width: 50%;
  height: 4.625rem;
  padding: 1.5rem 0 0 1.25rem;
}
html #f.f-embed .f-header-logo {
  height: auto;
  padding-top: 0;
}
#f .f-header-menu {
  display: flex;
  flex: 0 0 50%;
  align-items: center;
  justify-content: flex-end;
  max-width: 50%;
  padding: 1.5rem 1.25rem 0 0;
}
#f .f-wrapper {
  position: relative;
}
html #f.f-embed .f-wrapper {
  min-height: 500px;
}
#f .f-sidebar {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--fondy-container_bg);
  -webkit-transform: translateX(-1000%);
  transform: translateX(-1000%);
}
#f .f-open .f-sidebar {
  z-index: 990;
  transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1),
    -webkit-transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
#f .f-sidebar-wrap {
  padding: 0 1.25rem;
}
#f .f-sidebar-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
#f .f-top {
  background-image: linear-gradient(
    180deg,
    var(--fondy-container_bg),
    hsla(
      var(--fondy-container_bg-h),
      var(--fondy-container_bg-s),
      var(--fondy-container_bg-l),
      0
    )
  );
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
  #f .f-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
#f .f-top-inner {
  height: 1.5rem;
}
#f .f-center {
  height: 100%;
}
#f .f-center-wrap {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
}
#f .f-logo {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  color: var(--fondy-logo_color);
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: contain;
}
#f .f-logo svg {
  width: 6.25rem;
  height: 1.4375rem;
}
#f .f-button-pay {
  margin-bottom: 1rem;
}
#f .f-btn-methods {
  display: block;
  padding-right: 0;
  padding-left: 0;
}
#f .f-security {
  padding-top: 2rem;
  padding-bottom: 20px;
  margin-top: auto;
  text-align: center;
}
#f .f-payment-method-wallets .f-security {
  display: none;
}
#f .f-menu {
  margin-bottom: 2rem;
}
#f .f-menu-item {
  display: flex;
  align-items: center;
  width: 100%;
  height: 4.5rem;
  padding: 0 0.9375rem 0 0.4375rem;
  margin-bottom: 0.75rem;
  font-weight: 400;
  color: var(--fondy-menu_item_color);
  text-align: left;
  cursor: pointer;
  background-color: var(--fondy-container_bg);
  border: 1px solid var(--fondy-menu_item_border);
  border-radius: 0.5rem;
  transition: background 0.15s ease-in-out, color 0.15s ease-in-out;
}
#f .f-menu-item:hover {
  padding: 0 1rem 0 0.5rem;
  background: var(--fondy-menu_item_hover_bg);
  border: none;
}
#f .f-menu-item.active {
  font-weight: 500;
  color: var(--fondy-menu_item_active_color);
  background: var(--fondy-menu_item_active_bg);
}
#f .f-menu-item.active .f-menu-icon {
  color: var(--fondy-menu_icon_active_color);
}
#f .f-menu-item.active .f-icons-count {
  color: var(--fondy-menu_count_active_color);
  background: var(--fondy-menu_count_active_bg);
}
#f .f-menu-item.active .f-icon {
  opacity: 1;
}
#f .f-menu-item.active,
#f .f-menu-item:hover {
  text-decoration: none;
}
#f .f-menu-icon {
  color: var(--fondy-menu_icon_color);
  transition: all 0.15s ease-in-out;
}
#f .f-menu-icons {
  margin-left: auto;
}
#f .f-card {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 3.9375rem 0 1rem 1rem;
  margin-bottom: 2rem;
}
#f .f-card-shadow {
  position: absolute;
  bottom: 0;
  left: 10%;
  width: 80%;
  height: 2rem;
  content: '';
  border-radius: 1rem;
  box-shadow: 0 0.625rem 1.5625rem
    hsla(
      var(--fondy-card_shadow-h),
      var(--fondy-card_shadow-s),
      var(--fondy-card_shadow-l),
      0.6
    );
}
#f .f-theme-dark .f-card-shadow {
  box-shadow: 0 0.625rem 1.5625rem rgba(0, 0, 0, 0.6);
}
#f .f-card-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background: linear-gradient(
    180deg,
    hsl(
      var(--fondy-card_bg-h),
      calc(var(--fondy-card_bg-s) + 3%),
      calc(var(--fondy-card_bg-l) + 4%)
    ),
    var(--fondy-card_bg) 35%,
    hsl(
      var(--fondy-card_bg-h),
      calc(var(--fondy-card_bg-s)),
      calc(var(--fondy-card_bg-l) - 4%)
    )
  );
  background-clip: padding-box;
  border-radius: 1rem;
  box-shadow: var(--fondy-card_shadow_custom);
}
#f .f-card-bg-lighten {
  background: linear-gradient(
    180deg,
    hsl(
      var(--fondy-card_bg-h),
      calc(var(--fondy-card_bg-s) + 3%),
      calc(var(--fondy-card_bg-l) + 8%)
    ),
    hsl(
        var(--fondy-card_bg-h),
        calc(var(--fondy-card_bg-s)),
        calc(var(--fondy-card_bg-l) + 4%)
      )
      35%,
    var(--fondy-card_bg)
  );
}
#f .f-card-img {
  background: var(--fondy-card_img) no-repeat 0 0/100% 100%;
}
#f .f-card-gradient-custom {
  background: var(--fondy-card_gradient_custom);
}
#f .f-card-brand {
  position: absolute;
  top: 0.875rem;
  right: 0.875rem;
}
#f .f-card-brand,
#f .f-card-brand-icon {
  width: 5.25rem;
  height: 2.5rem;
  border-radius: 0.375rem;
}
#f .f-form-group-card {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 1.25rem 0 0;
}
#f .f-form-group-card .f-control-label {
  order: -1;
  margin-bottom: 0.375rem;
  font-size: 0.625rem;
  line-height: 0.75rem;
  color: var(--fondy-card_label_color);
  text-transform: uppercase;
  letter-spacing: 0.03em;
  opacity: 0.7;
}
#f .f-form-group-card .f-form-control {
  height: 1.125rem;
  padding: 0;
  font-family: Card Number, sans-serif;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.125rem;
  color: var(--fondy-card_input_color);
  text-shadow: 0.0625rem 0.0625rem 0.125rem
    hsla(
      var(--fondy-card_input_shadow-h),
      var(--fondy-card_input_shadow-s),
      var(--fondy-card_input_shadow-l),
      0.15
    );
  background-color: transparent;
  border: none;
  border-radius: 0;
}
#f .f-form-group-card .f-form-control:hover {
  background-color: inherit;
}
#f .f-form-group-card .f-form-control:-webkit-autofill:first-line {
  font-family: Card Number, sans-serif;
  font-size: 0.9375rem;
  line-height: 1.125rem;
  color: var(--fondy-card_input_color);
}
#f .f-form-group-card .f-form-control::-webkit-input-placeholder {
  font-family: Card Number, sans-serif;
  color: hsla(
    var(--fondy-card_input_color-h),
    var(--fondy-card_input_color-s),
    var(--fondy-card_input_color-l),
    0.3
  );
}
#f .f-form-group-card .f-form-control::-moz-placeholder {
  font-family: Card Number, sans-serif;
  color: hsla(
    var(--fondy-card_input_color-h),
    var(--fondy-card_input_color-s),
    var(--fondy-card_input_color-l),
    0.3
  );
}
#f .f-form-group-card .f-form-control:-ms-input-placeholder {
  font-family: Card Number, sans-serif;
  color: hsla(
    var(--fondy-card_input_color-h),
    var(--fondy-card_input_color-s),
    var(--fondy-card_input_color-l),
    0.3
  );
}
#f .f-form-group-card .f-form-control::-ms-input-placeholder {
  font-family: Card Number, sans-serif;
  color: hsla(
    var(--fondy-card_input_color-h),
    var(--fondy-card_input_color-s),
    var(--fondy-card_input_color-l),
    0.3
  );
}
#f .f-form-group-card .f-form-control::placeholder {
  font-family: Card Number, sans-serif;
  color: hsla(
    var(--fondy-card_input_color-h),
    var(--fondy-card_input_color-s),
    var(--fondy-card_input_color-l),
    0.3
  );
}
#f .f-form-group-card .f-form-control[readonly] {
  opacity: 0.6;
}
#f .f-form-group-card .f-form-control[readonly]::-webkit-input-placeholder {
  font-family: Cvv, sans-serif;
  color: var(--fondy-card_input_color);
}
#f .f-form-group-card .f-form-control[readonly]::-moz-placeholder {
  font-family: Cvv, sans-serif;
  color: var(--fondy-card_input_color);
}
#f .f-form-group-card .f-form-control[readonly]:-ms-input-placeholder {
  font-family: Cvv, sans-serif;
  color: var(--fondy-card_input_color);
}
#f .f-form-group-card .f-form-control[readonly]::-ms-input-placeholder {
  font-family: Cvv, sans-serif;
  color: var(--fondy-card_input_color);
}
#f .f-form-group-card .f-form-control[readonly]::placeholder {
  font-family: Cvv, sans-serif;
  color: var(--fondy-card_input_color);
}
#f .f-form-group-card-number {
  width: 100%;
  margin: 0 0 1.125rem 0;
}
#f #f-expiry_date {
  width: 5rem;
}
#f #f-cvv2 {
  width: 4.6875rem;
  font-family: Cvv, sans-serif;
}
#f .f-control-label-card-list {
  cursor: pointer;
}
#f .f-card-list {
  font-weight: 500;
}
#f .f-card-list-add,
#f .f-card-list-item {
  display: flex;
  height: 3.75rem;
  padding: 0.6875rem 1.25rem;
  margin-bottom: 0.8125rem;
  color: var(--fondy-main_color);
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;
}
#f .f-card-list-add:last-child,
#f .f-card-list-item:last-child {
  margin-bottom: 0;
}
#f .f-card-list-add.active,
#f .f-card-list-add:hover,
#f .f-card-list-item.active,
#f .f-card-list-item:hover {
  text-decoration: none;
  background-color: var(--fondy-card_list_item_active_bg);
}
#f .f-card-list-add {
  height: inherit;
}
#f .f-card-list-icon {
  width: 2.5rem;
  height: 1.25rem;
  margin-right: 0.25rem;
  color: var(--fondy-icon_color);
  border-radius: 0.375rem;
}
#f .f-card-list-number {
  color: var(--fondy-card_list_number_color);
}
#f .f-card-list-expiry-date {
  font-size: 0.75rem;
  font-weight: 400;
  color: var(--fondy-card_list_expiry_date_color);
}
#f .f-modal-security-title {
  margin-bottom: 2rem;
  color: var(--fondy-modal_security_title_color);
}
#f .f-modal-security-title .f-svg {
  margin-right: 0.625rem;
  color: var(--fondy-modal_security_title_svg_color);
}
#f .f-modal-security-content {
  margin-bottom: 2rem;
  font-weight: 300;
}
#f .f-modal-security-icons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  opacity: 0.5;
}
#f .f-modal-content-card-list {
  padding-top: 0.75rem;
}
#f .f-modal-body-card-list {
  padding: 0 0 0.75rem 0;
}
#f .f-success {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
#f .f-success-title {
  text-align: center;
}
#f .f-success-title svg {
  margin: -1rem 0;
}
#f .f-success-form-group {
  padding: 1rem;
  word-wrap: break-word;
  border: 1px solid var(--fondy-input_border);
  border-radius: 0.5rem;
}
#f .f-sidebar .f-wallet-pay-button {
  margin-bottom: 2rem;
}
#f .f-center .f-wallet-pay-button {
  margin-bottom: 1.5rem;
}
#f .f-wallet-pay-title {
  margin-bottom: 1rem;
  font-weight: 300;
}
#f .f-bank-item {
  display: flex;
  flex: 0 0 100%;
  align-items: center;
  max-width: 100%;
  margin-bottom: 1.5rem;
  color: var(--fondy-bank_item_color);
  cursor: pointer;
}
#f .f-bank-item:hover {
  color: var(--fondy-bank_item_hover_color);
  transition: all 0.15s ease-in-out;
}
#f .f-bank-item.f-bank-item-list {
  flex: 0 0 100%;
  max-width: 100%;
}
#f .f-bank-item-DE {
  align-items: flex-start;
}
#f .f-bank-item-wrapper-list {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
}
#f .f-bank-icon {
  min-width: 3rem;
  margin-right: 0.75rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.0625rem 0.25rem var(--fondy-bank_icon_shadow);
}
#f .f-bank-icon-list {
  min-width: 1.5rem;
  border-radius: 0.1875rem;
  box-shadow: none;
}
#f .f-bank-name {
  font-size: 0.875rem;
  word-wrap: break-word;
}
#f .f-bank-country,
#f .f-bank-search,
#f .f-bank-view {
  flex: 0 0 100%;
  max-width: 100%;
}
#f .f-bank-view-wrapper {
  display: flex;
  align-items: stretch;
  height: 2.75rem;
  border: 1px solid var(--fondy-input_border);
  border-radius: 0.5rem;
}
#f .f-bank-view-icon {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-right: 1px solid var(--fondy-input_border);
}
#f .f-bank-view-icon.f-active,
#f .f-bank-view-icon:hover {
  color: var(--fondy-bank_view_icon_active_color);
  background-color: var(--fondy-bank_view_icon_active_bg);
  transition: all 0.15s ease-in-out;
}
#f .f-bank-view-icon:first-child {
  border-radius: 0.4375rem 0 0 0.4375rem;
}
#f .f-bank-view-icon:last-child {
  border: none;
  border-radius: 0 0.4375rem 0.4375rem 0;
}
#f .f-bank-footer {
  text-align: center;
}
#f .f-bank-select {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.75rem;
  margin-bottom: 0.75rem;
  background-color: var(--fondy-bank_select_bg);
  border-radius: 0.5rem;
}
#f .f-bank-select-icon {
  margin-right: 0.75rem;
  border-radius: 0.5rem;
}
#f .f-bank-select-name {
  margin-right: 0.75rem;
}
#f .f-bank-select-close {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 1.25rem;
  font-size: 1.75rem;
  font-weight: 300;
  color: var(--fondy-bank_select_close_color);
}
#f .f-bank-select-close:hover {
  color: var(--fondy-bank_select_close_hover_color);
}
#f .f-bank-desc {
  margin-bottom: 1.25rem;
}
#f .f-subscription {
  padding: 1rem 0.75rem 0.75rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  background-color: var(--fondy-subscription_bg);
  border-radius: 0.5rem;
}
#f .f-subscription-wrapper {
  margin-top: 0.75rem;
}
#f .f-subscription-wrapper > :last-child {
  margin: 0;
}
#f .f-form-control-every {
  border-right: none;
  border-radius: 0.5rem 0 0 0.5rem;
}
#f .f-form-control-period {
  border-left: none;
  border-radius: 0 0.5rem 0.5rem 0;
}
#f .f-verification-desc {
  font-size: 0.75rem;
}
#f .f-fast-access {
  display: flex;
  flex-wrap: wrap;
}
#f .f-fast-access-icon {
  margin: 0 22px 1.25rem 0;
  cursor: pointer;
  border-radius: 0.5rem;
  box-shadow: 0 1px 4px var(--fondy-bank_icon_shadow);
}
#f .f-tooltip-security-icons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  opacity: 0.5;
}
#f .f-tooltip-security-content {
  font-size: 0.875rem;
  font-weight: 400;
}
#f .f-tooltip-security-content p:last-child {
  margin-bottom: 0;
}
#f .f-tooltip-icons .f-tooltip-inner {
  flex-wrap: wrap;
  max-width: 19rem;
  padding: 0.75rem 0.25rem 0.25rem 0.75rem;
}
#f .f-tooltip-icons .f-icon {
  margin: 0 0.5rem 0.5rem 0;
  border-radius: 0.1875rem;
  box-shadow: 0 0.0625rem 0.25rem var(--fondy-tooltip_icons_shadow);
}
#f .f-tooltip-error .f-tooltip-inner {
  color: var(--fondy-tooltip_error_color);
}
#f .f-tooltip-card .f-tooltip-inner {
  padding: 0.5rem 0;
  color: var(--fondy-tooltip_card_color);
}
#f .f-tooltip-cvv .f-tooltip-inner {
  color: var(--fondy-tooltip_cvv_color);
}
#f .f-receipt-title {
  text-align: left;
}
#f .f-receipt-props {
  padding: 1rem 1.25rem;
  margin: 0 -1.25rem 0.75rem;
  background: var(--fondy-receipt_props_bg);
}
#f .f-receipt-row {
  margin-bottom: 1.125rem;
}
#f .f-receipt-row:last-child {
  margin-bottom: 0;
}
#f .f-receipt-key {
  font-size: 0.875rem;
}
#f .f-receipt-value {
  color: var(--fondy-receipt_value_color);
}
#f .f-theme-light .f-receipt-value {
  font-weight: 600;
}
#f .f-receipt-buttons {
  margin-top: 1.5rem;
  margin-bottom: 2.125rem;
}
#f .f-receipt-ibox-info {
  display: flex;
}
#f .f-receipt-ibox-info svg {
  margin: 0.625rem 0.75rem 0 0;
}
#f .f-qr-code-img {
  margin-bottom: 1.5rem;
  text-align: center;
}
#f .f-qr-code-text {
  margin: 0 -2rem;
  font-size: 0.875rem;
  text-align: center;
}
#f .f-payment-method {
  display: flex;
  flex-direction: column;
  height: 100%;
}
#f .f-container-sm,
#f .f-payment-method-card-inner {
  width: 100%;
  max-width: 280px;
  margin: 0 auto;
}
#f .f-merchant-name {
  font-weight: 500;
  color: var(--fondy-merchant_name_color);
}
#f .f-merchant-url {
  margin-bottom: 0.75rem;
}
#f .f-merchant-url a {
  font-weight: 500;
  color: var(--fondy-merchant_url_color);
}
#f .f-merchant-url a:hover {
  color: hsl(
    var(--fondy-merchant_url_color-h),
    var(--fondy-merchant_url_color-s),
    calc(var(--fondy-merchant_url_color-l) - 10%)
  );
}
#f .f-order-desc {
  margin-bottom: 0.75rem;
}
#f .f-order-desc-text {
  color: var(--fondy-order_desc_color);
  position: relative;
  display: block;
  max-height: 2.8rem;
  overflow: hidden;
  line-height: 1.4;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: normal;
  background: var(--fondy-container_bg);
}
#f .f-order-desc-text:after {
  position: absolute;
  width: 100%;
  height: 100%;
  content: '';
  background-color: inherit;
}
#f .f-order-desc-text:before {
  position: absolute;
  top: 1.4rem;
  right: 0;
  content: '...';
  background-color: inherit;
}
#f .f-order-desc-more {
  font-weight: 600;
  color: var(--fondy-order_desc_more_color);
}
#f .f-order-desc-more:hover {
  color: hsl(
    var(--fondy-order_desc_more_color-h),
    var(--fondy-order_desc_more_color-s),
    calc(var(--fondy-order_desc_more_color-l) - 10%)
  );
}
#f .f-price {
  margin-bottom: 1.5rem;
}
#f .f-amount {
  display: inline-block;
  margin-right: 0.5rem;
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 1.2;
  color: var(--fondy-amount_color);
}
#f .f-amount sup {
  font-size: 1rem;
}
#f .f-currency {
  display: inline-block;
  font-size: 1.25rem;
  color: var(--fondy-currency_color);
}
#f .f-fee-key {
  flex: 0 0 7.5rem;
  max-width: 7.5rem;
}
#f .f-icons-title {
  margin-right: 1rem;
  font-weight: 300;
  letter-spacing: -0.01875rem;
}
#f .f-lang {
  padding: 0.5rem 1.75rem 0.5rem 0.5rem;
  color: var(--fondy-btn_link_default_color);
  border: none;
}
#f .f-icons {
  display: flex;
  align-items: center;
}
#f .f-icons .f-icon {
  margin-right: 0.5rem;
  border-radius: 0.1875rem;
  box-shadow: 0 0.0625rem 0.25rem var(--fondy-menu_icons_shadow);
}
#f .f-icons .f-icon:last-child {
  margin-right: 0;
}
#f .f-icons-count {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 0.625rem;
  line-height: 1.5rem;
  color: var(--fondy-menu_count_color);
  text-align: center;
  background: var(--fondy-menu_count_bg);
  border-radius: 0.1875rem;
  transition: all 0.15s ease-in-out;
}
#f .f-icons-count:hover {
  color: hsl(
    var(--fondy-menu_count_color-h),
    var(--fondy-menu_count_color-s),
    calc(var(--fondy-menu_count_color-l) - 10%)
  );
  text-decoration: none;
}
#f .f-title-security {
  font-size: 0.75rem;
  color: var(--fondy-security_color);
  text-align: center;
  cursor: pointer;
  transition: color 0.15s ease-in-out;
}
#f .f-title-security:hover {
  color: var(--fondy-security_hover_color);
  text-decoration: none;
}
#f .f-title-security:hover .f-svg-security {
  color: var(--fondy-security_icon_hover_color);
}
#f .f-title-security .f-svg {
  margin-right: 0.625rem;
}
#f .f-svg-security {
  color: var(--fondy-security_icon_color);
  vertical-align: middle;
  transition: color 0.15s ease-in-out;
}
#f .f-form-group-currency {
  position: absolute;
  top: 1.0625rem;
  right: 0.75rem;
  font-size: 1rem;
  font-weight: 500;
  color: var(--fondy-label_color);
  transition: all 0.15s ease-in-out;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
#f .f-active + .f-form-group-currency {
  -webkit-transform: translateY(7px);
  transform: translateY(7px);
}
#f .f-gdpr {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
  padding-bottom: 2rem;
  margin-bottom: 0;
  border-radius: 0.75rem 0.75rem 0 0;
}
#f .f-gdpr .f-checkbox-label:after {
  border-color: #6a747e;
}
#f .f-gdpr-content {
  line-height: 1.25rem;
}
#f .f-gdpr-buttons {
  text-align: center;
}
#f .f-demo-title {
  position: absolute;
  left: 50%;
  padding: 0.0625rem 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #fff;
  background: #30d769;
  border-radius: 0 0 0.5rem 0.5rem;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
#f .f-m-0 {
  margin: 0 !important;
}
#f .f-mt-0,
#f .f-my-0 {
  margin-top: 0 !important;
}
#f .f-mr-0,
#f .f-mx-0 {
  margin-right: 0 !important;
}
#f .f-mb-0,
#f .f-my-0 {
  margin-bottom: 0 !important;
}
#f .f-ml-0,
#f .f-mx-0 {
  margin-left: 0 !important;
}
#f .f-m-1 {
  margin: 0.25rem !important;
}
#f .f-mt-1,
#f .f-my-1 {
  margin-top: 0.25rem !important;
}
#f .f-mr-1,
#f .f-mx-1 {
  margin-right: 0.25rem !important;
}
#f .f-mb-1,
#f .f-my-1 {
  margin-bottom: 0.25rem !important;
}
#f .f-ml-1,
#f .f-mx-1 {
  margin-left: 0.25rem !important;
}
#f .f-m-2 {
  margin: 0.5rem !important;
}
#f .f-mt-2,
#f .f-my-2 {
  margin-top: 0.5rem !important;
}
#f .f-mr-2,
#f .f-mx-2 {
  margin-right: 0.5rem !important;
}
#f .f-mb-2,
#f .f-my-2 {
  margin-bottom: 0.5rem !important;
}
#f .f-ml-2,
#f .f-mx-2 {
  margin-left: 0.5rem !important;
}
#f .f-m-3 {
  margin: 1rem !important;
}
#f .f-mt-3,
#f .f-my-3 {
  margin-top: 1rem !important;
}
#f .f-mr-3,
#f .f-mx-3 {
  margin-right: 1rem !important;
}
#f .f-mb-3,
#f .f-my-3 {
  margin-bottom: 1rem !important;
}
#f .f-ml-3,
#f .f-mx-3 {
  margin-left: 1rem !important;
}
#f .f-m-4 {
  margin: 1.5rem !important;
}
#f .f-mt-4,
#f .f-my-4 {
  margin-top: 1.5rem !important;
}
#f .f-mr-4,
#f .f-mx-4 {
  margin-right: 1.5rem !important;
}
#f .f-mb-4,
#f .f-my-4 {
  margin-bottom: 1.5rem !important;
}
#f .f-ml-4,
#f .f-mx-4 {
  margin-left: 1.5rem !important;
}
#f .f-m-5 {
  margin: 3rem !important;
}
#f .f-mt-5,
#f .f-my-5 {
  margin-top: 3rem !important;
}
#f .f-mr-5,
#f .f-mx-5 {
  margin-right: 3rem !important;
}
#f .f-mb-5,
#f .f-my-5 {
  margin-bottom: 3rem !important;
}
#f .f-ml-5,
#f .f-mx-5 {
  margin-left: 3rem !important;
}
#f .f-p-0 {
  padding: 0 !important;
}
#f .f-pt-0,
#f .f-py-0 {
  padding-top: 0 !important;
}
#f .f-pr-0,
#f .f-px-0 {
  padding-right: 0 !important;
}
#f .f-pb-0,
#f .f-py-0 {
  padding-bottom: 0 !important;
}
#f .f-pl-0,
#f .f-px-0 {
  padding-left: 0 !important;
}
#f .f-p-1 {
  padding: 0.25rem !important;
}
#f .f-pt-1,
#f .f-py-1 {
  padding-top: 0.25rem !important;
}
#f .f-pr-1,
#f .f-px-1 {
  padding-right: 0.25rem !important;
}
#f .f-pb-1,
#f .f-py-1 {
  padding-bottom: 0.25rem !important;
}
#f .f-pl-1,
#f .f-px-1 {
  padding-left: 0.25rem !important;
}
#f .f-p-2 {
  padding: 0.5rem !important;
}
#f .f-pt-2,
#f .f-py-2 {
  padding-top: 0.5rem !important;
}
#f .f-pr-2,
#f .f-px-2 {
  padding-right: 0.5rem !important;
}
#f .f-pb-2,
#f .f-py-2 {
  padding-bottom: 0.5rem !important;
}
#f .f-pl-2,
#f .f-px-2 {
  padding-left: 0.5rem !important;
}
#f .f-p-3 {
  padding: 1rem !important;
}
#f .f-pt-3,
#f .f-py-3 {
  padding-top: 1rem !important;
}
#f .f-pr-3,
#f .f-px-3 {
  padding-right: 1rem !important;
}
#f .f-pb-3,
#f .f-py-3 {
  padding-bottom: 1rem !important;
}
#f .f-pl-3,
#f .f-px-3 {
  padding-left: 1rem !important;
}
#f .f-p-4 {
  padding: 1.5rem !important;
}
#f .f-pt-4,
#f .f-py-4 {
  padding-top: 1.5rem !important;
}
#f .f-pr-4,
#f .f-px-4 {
  padding-right: 1.5rem !important;
}
#f .f-pb-4,
#f .f-py-4 {
  padding-bottom: 1.5rem !important;
}
#f .f-pl-4,
#f .f-px-4 {
  padding-left: 1.5rem !important;
}
#f .f-p-5 {
  padding: 3rem !important;
}
#f .f-pt-5,
#f .f-py-5 {
  padding-top: 3rem !important;
}
#f .f-pr-5,
#f .f-px-5 {
  padding-right: 3rem !important;
}
#f .f-pb-5,
#f .f-py-5 {
  padding-bottom: 3rem !important;
}
#f .f-pl-5,
#f .f-px-5 {
  padding-left: 3rem !important;
}
#f .f-w-100 {
  width: 100%;
}
#f .f-text-right {
  text-align: right;
}
#f .f-align-items-center {
  align-items: center;
}
html .ipsp-modal {
  width: 100%;
  max-width: 590px;
}
html .ipsp-modal-header {
  height: 0;
}
html .ipsp-modal-close {
  position: absolute;
  top: 0;
  right: 0;
}
html .ipsp-modal-title {
  display: none;
}
html .ipsp-modal-content {
  min-height: 600px;
}
html .ipsp-modal-iframe {
  height: 600px;
}
@media (min-width: 375px) {
  #f .f-card {
    padding: 5.9375rem 0 1.21875rem 1rem;
  }
}
@media (min-width: 375px) {
  #f .f-form-group-card .f-control-label {
    margin-bottom: 0.25rem;
    font-size: 0.6875rem;
    line-height: 0.8125rem;
  }
}
@media (min-width: 375px) {
  #f .f-form-group-card .f-form-control {
    height: 1.5625rem;
    font-size: 1.25rem;
    line-height: 1.5625rem;
  }
}
@media (min-width: 375px) {
  #f .f-form-group-card .f-form-control:-webkit-autofill:first-line {
    font-size: 1.25rem;
    line-height: 1.5625rem;
  }
}
@media (min-width: 375px) {
  #f .f-amount {
    font-size: 2rem;
  }
}
@media (min-width: 375px) {
  #f .f-currency {
    font-size: 1.375rem;
  }
}
@media (min-width: 375px) {
  #f .f-container-sm,
  #f .f-payment-method-card-inner {
    width: 100%;
    max-width: 335px;
  }
}
@media (min-width: 375px) {
  #f .f-fast-access-icon {
    margin-right: 23px;
  }
  #f .f-fast-access-icon:nth-child(5n) {
    margin-right: 0;
  }
}
@media (min-width: 576px) {
  #f .f-container-sm,
  #f .f-payment-method-card-inner {
    max-width: 360px;
  }
}
@media (min-width: 576px) {
  #f .f-bank-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 576px) {
  #f .f-bank-country {
    flex: 0 0 13.75rem;
    max-width: 13.75rem;
  }
}
@media (min-width: 576px) {
  #f .f-bank-search {
    flex: 0 0 calc(100% - 20rem);
    max-width: calc(100% - 20rem);
  }
}
@media (min-width: 576px) {
  #f .f-bank-view {
    flex: 0 0 6.25rem;
    max-width: 6.25rem;
  }
}
@media (min-width: 576px) {
  #f .f-fast-access-icon {
    margin-right: 30px;
  }
}
@media (min-width: 576px) {
  #f .f-receipt-row {
    display: flex;
  }
}
@media (min-width: 576px) {
  #f .f-receipt-key {
    flex: 0 0 160px;
  }
}
