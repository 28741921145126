.TabsController {
  display: grid;
  grid-gap: 80px;

  .Tab {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding-bottom: 8px;
    &:hover {
      &::after {
        position: absolute;
        display: block;
        content: '';
        width: 100%;
        height: 3px;
        background-color: var(--color-primary-10);
        bottom: 0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px; } }
    .Title {
      margin-right: 4px; }
    .Counter {
      color: var(--color-tertiary-6);
      padding: 0px 4px;
      border-radius: 4px; } }

  .Tab_theme_active {
    &::after {
      position: absolute;
      display: block;
      content: '';
      width: 100%;
      height: 3px;
      background-color: var(--color-primary-10);
      bottom: 0;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px; }
    .Counter {
      background-color: var(--color-tertiary-1); } } }
