.TabGroup {
  padding: 2px;
  background-color: var(--color-tertiary-04);
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  .Tab {
    color: var(--color-tertiary-6);
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: var(--color-primary-10);
      color: #fff; } }
  .Tab_theme_active {
    background-color: var(--color-primary-10);
    color: #fff; } }
