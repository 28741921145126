.Header {
  width: 100%;
  height: 510px;
  display: flex;
  justify-content: center;
  align-items: center;
  .Header-Container {
    background-size: auto 800px;
    background-position: top -60px right 0px;
    background-repeat: no-repeat;
    height: 100%;
    display: flex;
    // justify-content: center
    align-items: center; }
  .Header-Offer {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
    width: 460px;
    margin-top: -32px;
    .Offer-Text {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 24px; }
    .Offer-Title {
      color: var(--color-primary-10);
      span {
        font-weight: 300; } }
    .Offer-Description {
      font-size: 18px;
      line-height: 26px;
      color: var(--color-tertiary-8); } }

  .Header-Buttons {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 24px; } }

@media screen and ( max-width:  576px) {
  .Header {
    height: 400px;
    .Header-Container {
      background-size: auto 350px;
      background-position: bottom 0px right -20px;
      position: relative;
      padding: 0;
      &::after {
        width: 100%;
        height: 100%;
        position: absolute;
        content: '';
        display: block;
        background-color: rgba(255,255,255, 0.5); } }
    .Header-Offer {
      width: 100%;
      position: relative;
      z-index: 1;
      .Offer-Title {
        font-size: 32px;
        line-height: 42px; }
      .Offer-Description {
        display: none; } } } }
@media screen and ( max-width: 450px) {
  .Header {
    .Header-Offer {
      width: 200px; } } }
