.OrdersList {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px; }
.OrderTile {
  h3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .OrderStatus {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500; } }
  .OrderNumber {
    color: var(--color-tertiary-6); }
  .Order-Body {
    margin-top: 16px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    .Line {
      display: flex;
      align-items: center;
      .Icon {
        color: var(--color-tertiary-6);
        margin-right: 8px; } }
    .InfoLine {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--color-tertiary-6);
      span {
        color: var(--color-tertiary-10); } } }
  .OrderDetails-Button {
    width: 100%; } }
