.CookieNotification {
  width: 100%;
  background: rgba(0,0,0, 0.8) !important;
  display: flex;
  align-items: center !important;
  font-size: 14px !important;
  padding: 0px 80px;
  font-size: 12px !important;
  .Icon {
    margin-right: 12px;
    font-size: 20px; }
  a {
    color: #fff;
    text-decoration: underline !important;
    &:hover {
      color: var(--color-primary-8); } } }
.CookieNotification-Button {
  height: 32px;
  border-radius: 4px !important;
  background: var(--color-primary-10) !important;
  color: #fff !important;
  font-size: 12px !important;
  line-height: 18px !important;
  font-weight: 500 !important;
  padding: 0px 16px !important; }

@media screen and ( max-width: 1366px ) {
  .CookieNotification {
    padding: 16px 16px 40px 16px;
    display: grid !important;
    grid-template-columns: 1fr !important; }
  .CookieNotification-Button {
    height: 40px;
    width: 100%;
    margin: 0px !important; } }
