.SitePage {
  .Header, .Footer, .Site-Content, .Site-PageHeader {
    padding-left: 80px;
    padding-right: 80px; }
  .Site-Content {
    padding-top: 32px;
    padding-bottom: 32px;
    position: relative; } }
.PageText-Container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  padding: 32px 200px !important; }
@media screen and ( max-width: 1366px ) {
  .SitePage {
    .Header, .Footer, .Site-Content, .Site-PageHeader {
      padding-left: 16px;
      padding-right: 16px; }
    .Footer {
      padding: 0; } }
  .PageText-Container {
    padding: 16px !important; } }
