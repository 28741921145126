.SignUp-Fields {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 16px;
  margin-bottom: 32px;
  // .Field
  //   &:last-child
 }  //     grid-column: 1/-1
.SignUp-Form {
  .Button_theme_primary {
    width: 100%; } }

@media screen and ( max-width: 1366px ) {
  .SignUp-Fields {
    grid-template-columns: 1fr; } }
