.Base {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 64px 0px;
  background-size: cover;
  background-position: center center;
  position: relative;
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(241, 247, 249, 0.85);
    position: absolute;
    top: 0;
    left: 0; }
  .SectionTitle {
    position: relative;
    z-index: 1; } }

.Base-Container {
  display: grid;
  grid-template-columns: 1fr 500px;
  grid-gap: 32px;
  position: relative;
  z-index: 1; }
.BaseImg {
  width: 500px;
  height: 400px;
  border-radius: 24px;
  .glide__slide {
    border-radius: 24px;
    height: 400px; }
  img {
    border-radius: 24px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center; }
  .Carousel-Arrow {
    background-color: var(--color-primary-6); } }
.Base-Text {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  .Benefits-Container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px; }
  .BenefitTile {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 8px;
    .Icon {
      font-size: 22px;
      color: var(--color-primary-8);
      margin-top: 4px; }
    .Title {
      font-size: 16px;
      font-weight: 700;
      color: var(--color-primary-11); }
    .Description {
      color: var(--description);
      font-size: 14px; } }

  .Button {
    justify-self: start; } }

@media screen and ( max-width: 1200px ) {
  .Base-Container {
    grid-template-columns: 400px 1fr;
    grid-gap: 24px; }
  .BaseVideo {
    height: 250px; } }
@media screen and ( max-width: 992px ) {
  .Base-Container {
    grid-template-columns: 1fr; }
  .BaseVideo {
    height: 300px; } }

@media screen and ( max-width: 576px ) {
  .BaseImg {
    justify-self: center;
    width: 345px;
    height: 280px;
    order: 0;
    .glide__slide {
      height: 280px; } }
  .Base-Text {
    order: 1;
    .Benefits-Container {
      grid-template-columns: 1fr; }
    .Button {
      justify-self: center; } } }
