.Contacts {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 64px 0px; }
.Contacts-Container {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.ContactMap {
  width: 100%;
  height: 350px;
  .Map {
    height: 350px; } }

.Contacts-RightSide {
  margin-right: 64px;
  flex-shrink: 0;
  max-width: 450px;

  h5 {
    font-size: 24px;
    font-weight: 500; }
  p {
    color: #8B8B8B;
    font-size: 14px;
    margin-top: 16px; }

  .IconText {
    margin-bottom: 16px;

    .IconText-Icon {
      margin-right: 16px;
      font-size: 18px;
      color: #000; } }

  .ActiveButton {
    margin-top: 16px; } }

.Contacts-Form {
  width: 350px;
  flex-shrink: 0;
  margin-left: 128px;
  text-align: center;

  .FormInput {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 24px; }

  textarea {
    height: 80px;
    resize: none; }

  .ActiveButton {
    width: 200px; } }

.MobileContact {
  display: none; }
