.ProfileNav {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
  align-self: start; }
.ProfileNavItem {
  padding: 8px 0px 8px 13px;
  display: flex;
  align-items: center;
  color: var(--color-tertiary-10);
  cursor: pointer;
  position: relative;
  .AngleRight {
    margin-left: auto;
    display: none; }
  .Icon {
    width: 20px;
    margin-right: 8px;
    color: var(--color-tertiary-6); }
  &:hover {
    color: var(--color-primary-10);
    background-color: var(--color-primary-1);
    &::before {
      display: none; }
    .Icon {
      color: var(--color-primary-10); }
    .AngleRight {
      display: block; } }

  &:active {
    background: none;
    .AngleRight {
      display: none; }
    &::before {
      display: block;
      content: '';
      position: absolute;
      width: 3px;
      height: 100%;
      background-color: var(--color-primary-10);
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      top: 0;
      left: 0; }
    .Icon {
      color: var(--color-primary-10); } } }
.ProfileNavItem_theme_active {
  .ProfileNavItem {
    color: var(--color-primary-10) !important;
    &::before {
      display: block;
      content: '';
      position: absolute;
      width: 3px;
      height: 100%;
      background-color: var(--color-primary-10);
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      top: 0;
      left: 0; }
    .Icon {
      color: var(--color-primary-10); } } }
