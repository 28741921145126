.Clinic {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  margin-top: 24px; }
.ClinicTile {
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  border: 1px var(--color-tertiary-2) solid;
  cursor: pointer;
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-gap: 64px;
  align-items: start;
  &:hover {
    border: 1px var(--color-primary-8) solid;
    box-shadow: var(--shadow-m); }
  .Address-Container {
    display: flex;
    align-items: start;
    .Title {
      color: var(--color-primary-10); }
    .Radio {
      margin-right: 24px; }
    .Address {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 16px; } }
  .Map {
    height: 100%;
    border-radius: 8px; } }

@media screen and ( max-width: 576px ) {
  .ClinicTile {
    grid-template-columns: 1fr;
    grid-gap: 24px;
    .Map {
      height: 250px; } } }
