.Backdrop {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  overflow: scroll;
  padding-bottom: 32px; }

.Backdrop_theme_transparent {
  background: none; }

// @media screen and ( max-width: 1366px )
//   .Backdrop
//     background-color: #fff
